import { UploadFile } from "antd";

export interface State {
    user: any;
    stores: any;
    selectedStore: any;
    selectedNode: any;
    pendingUploads: any;
    showLogo: boolean;
    title: string;

}
export interface FileUploadList {
    fileKey: string;
    file?: UploadFile;
    isUploaded: boolean;
    nodeData: any;
    cropper?: any;
    croppedImage?: File;
}
export interface User {
    isLogin: any;
}
export interface Node {
    store: string;
    node: string;
    height: number,
    nodeType: NodeTypes;
    posCode: string;
    posElement: string;
    weeks: Array<WEEK>;
    width: number;
    fileKey: string;
    fileKeys: Array<string>;
    clickedOn: string;
    fileMeta: File;
    size: string;
}

export interface WEEK {
    files: Array<File>;
    name: string;
}

export interface File {
    name: string;
    uploadedBy: string;
    uploadedOn: string;
    url: string;
}
export enum NodeTypes {
    POS_SINGLE = 'POS_SINGLE',
    POS_DOUBLE = 'POS_DOUBLE',
    MANNEQUINS = 'MANNEQUINS',
    VIDEO = 'VIDEO'
}


export interface Store {
    id: string,
    displayName: string,
    enabled: boolean,
    name: string
}

export interface User {
    userName: string,
    isActive: boolean,
    lastLoggedIn: string,
    password: string
}