import styled from "styled-components";
import { colors, isProd } from "../../utils";

const background = isProd() ? colors.prod : colors.dev;
export const Div = styled.div`
  width: 100%;
  height: 90px;
  margin-bottom: 20px;
  position: fixed;
  z-index: 1;
  background: ${background};
  .logoutButton {
    position: absolute;
    right: 20px;
    top: 10px;
  }
  .title-icon {
    width: 200px;
  }

  .legends {
    position: absolute;
    width: 15%;
    text-align: left;
    margin-left: 2rem;

    margin-top: 16px;
    float: left;
  }
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 5px;

    display: inline-flex;
  }
  .labels {
    display: flex;
    flex-direction: column;
  }
  .labels span {
    margin: 2px;
  }
  .cls-1 {
    background: #e60b43;
  }
  .cls-2 {
    background: #323232;
    font-family: Nunito-Regular, Nunito;
    font-size: 21px;
    font-variation-settings: "wght" 400;
  }
  .cls-3 {
    background: #fff;
  }
  .cls-4 {
    background: #61ed75;
  }
  .cls-5 {
    background: #3d62e3;
  }
  .cls-6 {
    background: #d332ed;
  }
  .cls-7 {
    background: #212320;
  }
  Button {
    margin-left: 10px;
  }
  .sub-header {
    font-size: 20px;
  }
`;
