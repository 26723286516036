import { useEffect, useState } from "react";
import {
  Button,
  Collapse,
  Input,
  Menu,
  message,
  Modal,
  Radio,
  Select,
  Space,
  Spin,
  Upload,
} from "antd";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import {
  InboxOutlined,
  CloudUploadOutlined,
  PlusCircleOutlined,
  EditOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { dataUrlToFileUsingFetch } from "./utils";
import { FileUploadList, NodeTypes, WEEK } from "./models/state";
import {
  addWeek,
  deleteNodeWeek,
  getS3CopyUrl,
  getS3HeadUrl,
  getS3PostUrl,
  getS3Url,
  hitCopy,
  uopdateImageMeta,
  uploadPhoto,
} from "./services";

const { Panel } = Collapse;
const { Dragger } = Upload;

const ImageUploader = (props: any) => {
  const [open, setOpen] = useState(false);
  const fileUploadListIn: FileUploadList[] = props.fileUploadListIn;
  const fileDataIn = props.fileDataIn;
  const { fileKey, fileKeys, wk, clickedOn, fileMeta, posData, ...nodeData } =
    props.selectedBoxIn;
  const [existingImageURL, setExistingImageURL] = useState<string>();
  const [fileList, setFileList] = useState<any>([]);
  const [fileUploadList, setFileUploadList] = useState<FileUploadList[]>([]);
  const [uploadedFileURL, setUploadedFileURL] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [cropper, setCropper] = useState<any>();
  const [oldCropper, setOldCropper] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isObjExists, setIsObjExists] = useState<any>();
  const [posElement, setPosElement] = useState(
    fileDataIn[nodeData.node].posElement
  );
  const [posCode, setPosCode] = useState(fileDataIn[nodeData.node].posCode);
  const [localPosData, setLocalPosData] = useState<any>({});
  const [fieldChanged, setFieldChanged] = useState<boolean>(false);
  const [isAddWeekActive, setIsAddWeekActive] = useState(false);
  const [newWeek, setNewWeek] = useState("");
  const [deleteWeek, setDeleteWeek] = useState<any>();
  const wkoptions = new Array(52)
    .fill(null)
    .map((a, i) => {
      return { value: `WK ${i + 1}`, label: `WK ${i + 1}` };
    })
    .filter((w) => !nodeData?.weeks.some((s: any) => s.name === w.label));

  const setPosData = () => {
    props.onFileDataUpdate({
      ...fileDataIn,
      [nodeData.node]: {
        posCode: posCode,
        posElement: posElement,
      },
    });
  };
  const handleFieldChange = (field: string, value: string) => {
    if (field === "posCode") {
      setPosCode(value);
    }
    if (field === "posElement") {
      setPosElement(value);
    }
    setFieldChanged(true);
    setLocalPosData({ ...localPosData, [field]: value });
  };
  const restComponentData = () => {
    setPosData();
    setPosCode("");
    setPosElement("");
    setLocalPosData({});
    setFileList([]);
    setUploadedFileURL(null);
    setExistingImageURL("");
    setOpen(false);
    setOldCropper(null);
    setEditMode(false);
    setNewWeek("");
    setIsAddWeekActive(false);
    setUploading(false);
    setFieldChanged(false);
  };

  const handleCropInIt = (instance: Cropper) => {
    setCropper(instance);
  };

  const getCropData = async (
    fileKeyIn: string,
    isFromClose: boolean = false
  ) => {
    const data = fileUploadList.find((d) => fileKeyIn === d.fileKey);
    const cropperIn = fileKeyIn == fileKey ? cropper : data?.cropper;

    if (data?.nodeData.nodeType === NodeTypes.VIDEO) {
      if (isFromClose) {
        restComponentData();
      }
      return null;
    }
    if (typeof data?.cropper !== "undefined" && data.file) {
      let updatedFile = cropperIn.getCroppedCanvas().toDataURL();
      const file = await dataUrlToFileUsingFetch(
        updatedFile,
        data.fileKey,
        data.file?.type || "image/jpeg"
      );
      addRemoveFile(data.file, file);
      if (isFromClose) {
        restComponentData();
      }
      return file;
    }
  };
  const handleCancel = async () => {
    if (uploadedFileURL) {
      await getCropData(fileKey, true);
    }
    {
      restComponentData();
    }
  };
  const getLatestUrl = async () => {
    try {
      const res = await getS3Url(fileKey);
      const { url } = res.data;
      // getObjHead();
      setExistingImageURL(url);
      setLoading(false);
    } catch {
      message.error("Failed to load.");
    }
  };
  const handlePosChange = async () => {
    setLoading(true);
    await uopdateImageMeta(
      nodeData.node,
      nodeData.store,
      fileKey,
      props.user,
      posCode,
      posElement
    );
    props.onCopyComplete();
    setLoading(false);
    setFieldChanged(false);
    message.success("Data saved successfully");
  };
  const handleCopy = async (data: any) => {
    setLoading(true);
    const res = await getS3CopyUrl(fileKey, data.file);
    await uopdateImageMeta(
      nodeData.node,
      nodeData.store,
      fileKey,
      props.user,
      posCode,
      posElement
    );
    const { url } = res.data;
    try {
      message.success("File Copied successfully");
      props.onCopyComplete();
      setLoading(false);
    } catch (err: any) {
      message.error("Failed to copy File");
      setLoading(false);
    }
  };
  const getObjHead = async (keys: any) => {
    const res = await getS3HeadUrl(keys.map((d: any) => d.file).join(","));
    const { output } = res.data;
    setIsObjExists(
      keys.filter((k: any) => output.some((d: any) => k.file === d))
    );

    // [...isObjExists, {file: key, week: week, side}]
  };
  useEffect(() => {}, [isObjExists]);
  useEffect(() => {
    setLoading(true);
    if (
      fileUploadListIn.findIndex((d) => d.fileKey === fileKey) !== -1 &&
      fileUploadListIn.find((d) => d.fileKey === fileKey)?.isUploaded === false
    ) {
      const Obj = fileUploadListIn.find(
        (d) => d.fileKey === fileKey
      ) as FileUploadList;
      setFileList([Obj?.file]);
      setOldCropper(Obj.cropper);
    } else {
      getLatestUrl();
      setFileList([]);
      setUploadedFileURL(null);
      setCropper(null);
    }
    setPosCode(localPosData?.posCode || fileDataIn[nodeData.node].posCode);
    setPosElement(
      localPosData?.posElement || fileDataIn[nodeData.node].posElement
    );
    setOpen(true);
    prepareCopyArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileKey, clickedOn, wk]);

  const prepareCopyArray = async () => {
    const keys: any = [];
    nodeData?.weeks.forEach(async (week: any) => {
      (week.files as [])?.forEach(async (file: any, i) => {
        keys.push({ file: file.name, week: week.name, side: i });
      });
    });
    getObjHead(keys);
  };
  const uploadSingleFIle = async (filleKeyIn: string) => {
    try {
      const lFile = await getCropData(filleKeyIn);

      const { data } = await getS3PostUrl(filleKeyIn);
      await uploadPhoto(data.url, lFile ? lFile : fileList[0], {
        contentType: lFile ? lFile.type : fileList[0].type,
      });
      await uopdateImageMeta(
        nodeData.node,
        nodeData.store,
        filleKeyIn,
        props.user,
        posCode,
        posElement
      );
      updateFileUploadList(filleKeyIn, true);
      handleUpload();
    } catch {
      message.error("Failed to upload");
      updateFileUploadList(filleKeyIn, false);
      setUploading(false);
    }
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      const tobeUploaded = fileUploadList.filter(
        (d) => d.nodeData.node === nodeData.node && !d.isUploaded
      );
      if (tobeUploaded.length !== 0) {
        await uploadSingleFIle(tobeUploaded[0].fileKey);
      } else {
        restComponentData();
        message.success("File uploaded successfully");
      }
    } catch {
      setUploading(false);
    }
  };
  const getUploadText = () => {
    if (nodeData.nodeType == NodeTypes.MANNEQUINS) {
      return (
        <span>
          <h2>Drag and Drop here to update</h2>
          <span> (Supported format : PNG)</span>
        </span>
      );
    } else if (nodeData.nodeType == NodeTypes.VIDEO) {
      return (
        <span>
          <h2>Drag and Drop here to update</h2>
          <span> {"(Supported format : MP4 < 6MB)"}</span>
          <br />
          <span> {"Idel resolution: width 742px x height 1080px"}</span>
        </span>
      );
    } else {
      return (
        <span>
          <h2>Drag and Drop here to update</h2>
          <span> {"(Supported format : JPG)"}</span>
        </span>
      );
    }
  };
  useEffect(() => {
    let fileReader: FileReader;
    let isCancel = false;
    if (fileList.length > 0) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        // @ts-ignore
        const { result } = e.target;
        if (result && !isCancel) {
          setUploadedFileURL(result);
          setLoading(false);
        }
      };
      // @ts-ignore
      fileReader.readAsDataURL(fileList[0]);
    } else {
      setUploadedFileURL(null);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [fileList]);

  const addRemoveFile = (
    file: UploadFile | null | undefined,
    croppedFile?: File
  ) => {
    const index = fileUploadList.findIndex((d) => d.fileKey === fileKey);
    const newFileList = fileUploadList.slice();
    if (index !== -1) {
      newFileList.splice(index, 1);
    }
    if (file) {
      const newData = {
        file: file,
        nodeData: { ...props.selectedBoxIn },
        fileKey,
        isUploaded: false,
        cropper,
        croppedImage: croppedFile,
      } as FileUploadList;

      const newList = [...newFileList, newData];
      setFileUploadList(newList);
      props.onListUpdate(newList);
    } else {
      setFileUploadList(newFileList);
      props.onListUpdate(newFileList);
    }
  };

  const updateFileUploadList = (fileKeyIn: string, status: boolean) => {
    const index = fileUploadList.findIndex((d) => d.fileKey === fileKeyIn);
    const newFileList = fileUploadList.slice();
    const [uploadedFile] = newFileList.splice(index, 1);
    uploadedFile.isUploaded = status;
    if (status) {
      delete uploadedFile.croppedImage;
      delete uploadedFile.cropper;
      delete uploadedFile.file;
    }
    const newList = [...newFileList, uploadedFile];
    setFileUploadList(newList);
    props.onListUpdate(newList);
  };
  const getASP = (): number => {
    return nodeData.width && nodeData.height
      ? nodeData.width / nodeData.height
      : NaN;
  };

  const getAllowedFiles = (): Array<string> => {
    if (nodeData.nodeType == NodeTypes.MANNEQUINS) {
      return ["image/png"];
    } else if (nodeData.nodeType == NodeTypes.VIDEO) {
      return ["video/mp4"];
    } else {
      return ["image/jpeg", "image/jpg"];
    }
  };
  const uploadProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setCropper(null);
      addRemoveFile(null);
      setUploadedFileURL(null);
    },
    beforeUpload: (file) => {
      if (!getAllowedFiles().some((d) => d === file.type)) {
        message.error("Invalid file format");
        return false;
      }
      if (nodeData.nodeType == NodeTypes.VIDEO && file.size > 6 * 1024 * 1024) {
        message.error("File size should be less than 6MB");
        return false;
      }
      setFileList([file]);
      addRemoveFile(file);
      return false;
    },
    fileList,
    multiple: false,
    maxCount: 1,
  };
  const onFileChange = async (file: any) => {
    if (file) {
      if (uploadedFileURL) {
        await getCropData(fileKey, false);
      }
      props.onFileToggle(file.target.value);
    }
  };
  const nodeFileChanges = (): boolean => {
    return (
      fileUploadList.filter(
        (d) => !d.isUploaded && d.nodeData.node == nodeData.node
      ).length === 0
    );
  };
  const handleDelete = async () => {
    let wkL = deleteWeek.label;
    const { data } = await deleteNodeWeek(
      deleteWeek.key,
      nodeData.node,
      nodeData.store
    );
    message.success(`${newWeek} deleted successfully`);
    let fileKeys = nodeData.weeks
      .find((d: WEEK) => d.name === wkL)
      .files.map((f: File) => f.name);
    setDeleteWeek(null);
    const newList = [
      ...fileUploadList.filter((f) => fileKeys.indexOf(f.fileKey) == -1),
    ];
    setFileUploadList(newList);
    props.onListUpdate(newList);
    if (wk === wkL) {
      props.onDeleteWeekChange();
    } else {
      props.onAddWeekChange(wk);
    }
  };
  const onWeekChange = async (e: any, wk: any) => {
    if (e.domEvent.target.nodeName === "svg") {
      setDeleteWeek(wk);
      return;
    }
    if (wk) {
      if (uploadedFileURL) {
        await getCropData(fileKey, false);
      }
      props.onWeekToggle(wk.key);
    }
  };

  const getSize = () => {
    return `w${nodeData.width} x h${nodeData.height}`;
  };
  const onWeekAdd = async () => {
    const { data } = await addWeek(newWeek, nodeData.node, nodeData.store);
    message.success(`${newWeek} added successfully`);
    setNewWeek("");
    setIsAddWeekActive(false);
    props.onAddWeekChange(newWeek);
  };
  return (
    <>
      <Modal
        title={`Location ${nodeData.node} : ${wk}`}
        open={open}
        width={"70rem"}
        closable={false}
        onCancel={handleCancel}
        maskClosable={false}
        footer={
          editMode ? (
            nodeFileChanges() && fieldChanged ? (
              [
                <Button
                  type="primary"
                  onClick={handlePosChange}
                  disabled={!posCode || !posElement}
                  loading={uploading}
                  icon={!uploading ? <CloudUploadOutlined /> : null}
                >
                  {uploading ? "Updating" : "Instant Update"}
                </Button>,
                <Button
                  disabled={nodeFileChanges()}
                  key="back"
                  onClick={handleCancel}
                  icon={<PlusCircleOutlined />}
                >
                  Add to Batch
                </Button>,
              ]
            ) : (
              [
                <Button
                  type="primary"
                  onClick={handleUpload}
                  disabled={nodeFileChanges() || !posCode || !posElement}
                  loading={uploading}
                  icon={!uploading ? <CloudUploadOutlined /> : null}
                >
                  {uploading ? "Updating" : "Instant Update"}
                </Button>,
                <Button
                  disabled={nodeFileChanges()}
                  key="back"
                  onClick={handleCancel}
                  icon={<PlusCircleOutlined />}
                >
                  Add to Batch
                </Button>,
              ]
            )
          ) : (
            <Button key="back" onClick={handleCancel}>
              Close
            </Button>
          )
        }
      >
        <div className="model-card">
          <Modal
            title={`Do you want to delete ${deleteWeek?.label}`}
            open={deleteWeek?.key ? true : false}
            cancelText="No"
            okText="Yes"
            onOk={handleDelete}
            onCancel={() => setDeleteWeek(null)}
          ></Modal>
          <div className="p-3">
            {!editMode && (
              <div className="add-week">
                {isAddWeekActive ? (
                  <>
                    <span> Add Week</span>
                    <Select
                      className="add-week-select"
                      showSearch
                      placeholder="Select a Week"
                      optionFilterProp="children"
                      onChange={(e) => setNewWeek(e)}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={wkoptions}
                    />
                    <br />
                    <Button disabled={!newWeek} onClick={onWeekAdd}>
                      Add
                    </Button>{" "}
                    <Button
                      onClick={() => {
                        setNewWeek("");
                        setIsAddWeekActive(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Menu
                      onSelect={() => setIsAddWeekActive(true)}
                      items={[{ key: "Add_Week", label: "Add New Week" }]}
                    />
                  </>
                )}
              </div>
            )}
            <div className="week-overflow">
              <Menu
                // onSelect={onWeekChange}
                selectedKeys={[wk]}
                // items={}
              >
                {nodeData?.weeks
                  .map((s: any) => ({
                    key: s.name,
                    label: s.name,
                  }))
                  .map((s: any) => (
                    <Menu.Item key={s.key} onClick={(e) => onWeekChange(e, s)}>
                      <span className="week-span">{s.label}</span>{" "}
                      <DeleteOutlined className="deleteIcon" />
                    </Menu.Item>
                  ))}
              </Menu>
            </div>
          </div>
          <div className="p-1">
            <div className="input-section">
              <div className="input-row">
                <div className="labelDiv">POS Element:</div>
                <Input
                  disabled={!editMode}
                  onChange={(e) =>
                    handleFieldChange("posElement", e.target.value)
                  }
                  placeholder="POS Element"
                  value={posElement}
                />
              </div>
              <div className="input-row">
                <div className="labelDiv">POS Code:</div>
                <Input
                  disabled={!editMode}
                  onChange={(e) => handleFieldChange("posCode", e.target.value)}
                  placeholder="POS code"
                  value={posCode}
                />
              </div>
              {nodeData?.size && nodeData?.nodeType !== "MANNEQUINS" ? (
                <div className="input-row">
                  <div className="labelDiv">Real World Size:</div>
                  <div className="labelDivR">{nodeData.size}</div>
                </div>
              ) : (
                <></>
              )}
              {fileMeta?.uploadedOn && (
                <div className="input-row">
                  <div className="labelDiv">Updated on:</div>
                  <div className="labelDivR">
                    {fileMeta?.uploadedOn
                      ? new Date(fileMeta?.uploadedOn || "").toLocaleString()
                      : ""}
                  </div>
                </div>
              )}
              {fileMeta?.uploadedBy && (
                <div className="input-row">
                  <div className="labelDiv">Updated by:</div>
                  <div className="labelDivR">{fileMeta?.uploadedBy}</div>
                </div>
              )}
            </div>
            {editMode ? (
              <>
                {!fileList?.length && (
                  <div>
                    <Collapse className="copy-section">
                      <Panel header="Copy from" key="1">
                        {isObjExists?.filter((d: any) => d.file !== fileKey)
                          .length !== 0 && (
                          <p>
                            On click on below options the image will be copied
                            to current week/side,No need to upload/commit again{" "}
                          </p>
                        )}
                        <div className="copy-panel">
                          {isObjExists
                            ?.filter((d: any) => d.file !== fileKey)
                            .map((d: any) => {
                              return (
                                <>
                                  <Button
                                    className="copy-button"
                                    onClick={() => handleCopy(d)}
                                  >
                                    {" "}
                                    {`${d.week}${
                                      fileKeys?.length === 1
                                        ? ""
                                        : d.side
                                        ? "-side B"
                                        : "-side A"
                                    }`}
                                  </Button>{" "}
                                  {""}
                                </>
                              );
                            })}
                          {isObjExists?.filter((d: any) => d.file !== fileKey)
                            .length === 0 && (
                            <h4> No eligible files available to copy</h4>
                          )}
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                )}
                <div>
                  <Dragger {...uploadProps}>
                    <span className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </span>
                    <span className="ant-upload-text">{getUploadText()}</span>
                  </Dragger>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="p-2">
            <div className="edit-section">
              {!editMode ? (
                <Button
                  disabled={fileKey === "DUMMYIMAGEWITHNOWEEK"}
                  type="primary"
                  onClick={() => setEditMode(true)}
                  icon={<EditOutlined />}
                >
                  Edit
                </Button>
              ) : (
                <>
                  {" "}
                  <Button
                    type="primary"
                    onClick={() => setEditMode(false)}
                    icon={<CloseCircleOutlined />}
                  >
                    Cancel
                  </Button>
                </>
              )}
              {/* <CloseOutlined onClick={() => setEditMode(false)} /> <SaveOutlined /> */}
            </div>
            <div className="upload-div">
              {fileKeys?.length > 1 ? (
                <>
                  <Radio.Group value={fileKey} onChange={onFileChange}>
                    {fileKeys?.map((d: string, i: number) => (
                      <Radio.Button value={d}>{`Side-${
                        i ? "B" : "A"
                      }`}</Radio.Button>
                    ))}
                  </Radio.Group>
                  <br />
                </>
              ) : (
                <></>
              )}

              {fileKey !== "DUMMYIMAGEWITHNOWEEK" ? (
                !loading ? (
                  <>
                    {uploadedFileURL ? (
                      nodeData.nodeType != NodeTypes.VIDEO ? (
                        <>
                          {
                            <Cropper
                              className="image-preview1"
                              aspectRatio={getASP()}
                              preview=".img-preview"
                              src={uploadedFileURL}
                              viewMode={1}
                              minCropBoxHeight={10}
                              data={oldCropper?.getData()}
                              minCropBoxWidth={10}
                              background={false}
                              responsive={true}
                              checkOrientation={false}
                              onInitialized={(instance) => {
                                handleCropInIt(instance);
                              }}
                              guides={true}
                            />
                          }
                        </>
                      ) : (
                        <>
                          <video className="image-preview1" controls>
                            <source
                              src={uploadedFileURL || ""}
                              type="video/mp4"
                            />
                          </video>
                        </>
                      )
                    ) : (
                      <>
                        {existingImageURL ? (
                          nodeData.nodeType != NodeTypes.VIDEO ? (
                            <img
                              className="image-preview1"
                              src={existingImageURL}
                              alt="No Existing Image"
                            />
                          ) : (
                            <>
                              {/* <PlaySquareOutlined className="video-icon" />
                        <br />
                        <span>Video</span> */}
                              <video className="image-preview1" controls>
                                <source
                                  src={existingImageURL || ""}
                                  type="video/mp4"
                                />
                              </video>
                            </>
                          )
                        ) : (
                          <>
                            <Button onClick={handleCopy}> Copy</Button>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    <Space className="loading-div">
                      <Spin tip="Loading" size="large"></Spin>
                    </Space>
                  </>
                )
              ) : (
                <>
                  <h2 className="noWeek">Please Add Week</h2>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImageUploader;
