import styled from 'styled-components'

export const Div = styled.div`
margin-bottom: 10rem;
.cls-1{fill:#e60b43;}
.cls-2{fill:#fff;}
.cls-3{fill:#323232;font-family:FuturaBT-Medium,
    'Futura Md
    BT';font-size:21px;font-weight:500;}
.cls-4{fill:#61ed75;}
.cls-5{fill:#3d62e3;}
.cls-6{fill:#d332ed;}
.cls-7{fill:#212320;}
.cls-8,.cls-9{stroke:#c6c6c6;}
.cls-8,.cls-9,.cls-10,.cls-11{fill:none;}
.cls-8,.cls-9,.cls-11{stroke-linecap:round;stroke-linejoin:round;}
.cls-8,.cls-10,.cls-11{stroke-width:1.22px;}
.cls-9{stroke-width:1.37px;}
.cls-10{stroke-miterlimit:10;}
.cls-10,.cls-11{stroke:#fff;}
circle{
    cursor: pointer;
}
`