import styled from 'styled-components'

export const Div = styled.div`
display: flex;
flex-wrap: wrap;
.stores{
    display: flex;
    flex-wrap: wrap;
    margin: 2rem;
    margin-right: 10rem;
    margin-left: 10rem;
    justify-content: center;
}
.subHeader{
    width: 100%;
    
    font-size: 22px;
    font-weight: 500;
}

.storeMain{
    display: flex;
    
    flex-direction: column;
}
.storeMain span{
    
    font-size: 11px;
}
.store{
    display: table;
    margin: 20px;
    margin-bottom: 0px;
    height: 100px;
    width: 100px;
    background: #c4c4c4;
    
    

}
.store span{
    display: table-cell;
    vertical-align: middle;
    font-size: 16px !important;
}
.store_disabled{
    background: #c4c4c4 !important;
    cursor: not-allowed !important;
}
.store:hover{
    background: #ffffff;
    cursor: pointer;
}
`