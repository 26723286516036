import React, { useState } from "react";
import { Checkbox, Input, message, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { auth, getStores } from "../services";
import { MailOutlined } from "@ant-design/icons";
import { colors, isProd } from "../utils";
const Login = (props: any) => {
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [tcCheck, setTcCheck] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    message.destroy();
    setIsLoading(true);
    event.preventDefault();

    if (username && password) {
      const { data } = await auth(username, password);
      if (data.isLogin) {
        message.info("Login Success");
        localStorage.setItem("authToken", data.token);
        props.login(data);
        updateStores();
        setIsLoading(false);
        navigate("/stores");
        return;
      } else {
        localStorage.setItem("authToken", "");
        setIsLoading(false);
      }
    }
    message.error("Invalid Credentials");
  };

  const updateStores = async () => {
    const { data } = await getStores();
    props.setStores(data);
  };

  return (
    <div className="login-div">
      <span className="sub-head">Virtual Store Content Portal</span>
      <Input
        autoComplete="false"
        type="text"
        value={username}
        placeholder="Username"
        onChange={(event) => setUsername(event.target.value)}
      />
      <div className="password-div">
        <Input
          className="password-field"
          value={password}
          placeholder="Password"
          type={!showPassword ? "password" : "text"}
          onChange={(event) => setPassword(event.target.value)}
        />
        {!showPassword ? (
          <img onClick={() => setShowPassword(true)} src="PassEyeClosed.png" />
        ) : (
          <img onClick={() => setShowPassword(false)} src="PassEyeOpen.png" />
        )}
      </div>

      <br />
      <button
        className="loginButton"
        value={"Login"}
        disabled={!(username && password && tcCheck && !isLoading)}
        type="button"
        onClick={handleSubmit}
      >
        {!isLoading ? "Login" : <Spin />}
      </button>
      <span className="tc-row">
        <Checkbox onChange={() => setTcCheck(!tcCheck)}>
          <span className="tcLabel"></span>
        </Checkbox>{" "}
        <input
          className="tc"
          style={{ background: isProd() ? colors.prod : colors.dev }}
          onClick={() => window.open(process.env.REACT_APP_TC_URL, "blank")}
          value={"Agree to our T’s and C’s"}
          type="button"
        />
      </span>
      <br />
      <br />
      <br />
      <br />
      <br />
      <span>
        <input
          className="tc"
          style={{ background: isProd() ? colors.prod : colors.dev }}
          onClick={() =>
            window.open(process.env.REACT_APP_REQUEST_ACCESS_URL, "blank")
          }
          value={"Request access"}
          type="button"
        />
      </span>
      <span className="rc">
        <MailOutlined
          onClick={() =>
            window.open(process.env.REACT_APP_REQUEST_ACCESS_URL, "blank")
          }
          style={{ fontSize: "2rem", paddingTop: "1rem" }}
        />
      </span>
    </div>
  );
};
// const getLoginButton = ()=>{
//   return <><Spin /></>
// }
const mapStateToProps = (state: any) => {
  return {
    isLogin: state.user.isLogin,
  };
};

// Map dispatch actions to props
const mapDispatchToProps = (dispatch: any) => {
  return {
    login: (user: any) => dispatch({ type: "LOGIN", user }),
    setStores: (stores: any) => dispatch({ type: "UPDATE_STORES", stores }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
