import { createStore } from 'redux';

// Initial state
const initialState = {
  user: {
    isLogin: false,
  },
  stores: [],
  selectedStore: null,
  selectedNode: null,
  pendingUploads: [],
  title: '',
  showLogo: true
};

// Reducer function
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return login(state, action.user);
    case 'SET_NAV':
      return setNav(state, action.data);
    case 'LOGOUT':
      return logout(state, {});
    case 'UPDATE_STORES':
      return updateStores(state, action.stores);
    case 'SET_SELECTED_NODE':
      return setSelectedNode(state, action.selectedNode);
    case 'SET_SELECTED_STORE':
      return setSelectedStore(state, action.selectedStore);
    default:
      return state;
  }
};

const updateStores = (state, stores) => {
  return {
    ...state,
    stores: [...stores]
  }
}
const setNav = (state, data) => {
  return {
    ...state,
    title: data?.title,
    showLogo: data.showLogo
  }
}

const setSelectedStore = (state, store) => {
  return {
    ...state,
    selectedStore: { ...store }
  }
}

const setSelectedNode = (state, node) => {
  return {
    ...state,
    selectedNode: { ...node }
  }
}

const login = (state, user) => {
  return {
    ...state,
    user: {
      ...user,
      isLogin: true
    }
  }
}

const logout = (state, user) => {
  return {
    ...state,
    user: {
      ...user,
      isLogin: false
    }
  }
}

// Create store
const store = createStore(reducer);

export default store;