// import React, { useEffect, useState } from "react";
// import { Button, Input, message } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { Div } from "./styles";
import { State } from "../../models/state";
import { Button, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { LogoutOutlined } from "@ant-design/icons";

const NavBar = (props: any) => {
  const { isLogin, showLogo, title } = props;
  const [showModel, setShowModel] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLogin) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);
  let location = useLocation();

  useEffect(() => {
    if (!isLogin && location.pathname != "/") {
      message.error("Please Login Again");
      navigate("/");
    } else if (isLogin && location.pathname == "/stores") {
      props.setNav({ title: "", showLogo: true });
    } else if (isLogin && location.pathname != "/") {
      props.setNav({ title: props.selectedStore.displayName, showLogo: false });
    } else if (isLogin && location.pathname == "/") {
      navigate("/stores");
    }
  }, [location]);
  const handelLogout = () => {
    localStorage.setItem('authToken', '')
    props.setNav({ title: "", showLogo: true });
    props.logout();
    setShowModel(false);
  };
  return (
    <Div>
      {!showLogo && (
        <div className="legends">
          <div className="labels">
            <span>
              <span className="cls-5 circle"></span> Mannequins
            </span>
            <span>
              <span className="cls-1 circle"></span> POS - Single Sided
            </span>
            <span>
              <span className="cls-4 circle"></span> POS - Double Sided
            </span>
            <span>
              <span className="cls-6 circle"></span> Video
            </span>
          </div>
        </div>
      )}
      <br />
      <h1>
        <span>
          {showLogo ? (
            <img className="title-icon" src={"metaspace-logo.png"} />
          ) : (
            <>{title}</>
          )}
          <br />
          {!showLogo && <span className="sub-header">Select a location to edit content</span>}
        </span>
        <span className="logoutButton">
          {isLogin ? (
            <>
              <Button onClick={() => window.open(process.env.REACT_APP_THREE_D_SITE_URL, 'blank')} type="primary">
                Visit 3D Site
              </Button>
              <Button
                icon={<LogoutOutlined />}
                onClick={() => setShowModel(true)}
                type="primary"
              >
                logout
              </Button>
            </>
          ) : (
            <></>
          )}
        </span>
      </h1>
      <br />
      <Modal
        title="Do you want to logout"
        open={showModel}
        cancelText="No"
        okText="Yes"
        onOk={handelLogout}
        onCancel={() => setShowModel(false)}
      ></Modal>
    </Div>
  );
};
const mapStateToProps = (state: State) => {
  return {
    isLogin: state.user.isLogin,
    showLogo: state.showLogo,
    title: state.title,
    selectedStore: state.selectedStore,
  };
};

// Map dispatch actions to props
const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => dispatch({ type: "LOGOUT" }),
    setNav: (data: any) => dispatch({ type: "SET_NAV", data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
