import axios from "axios";
import { Node, WEEK } from "../models/state";

export const getS3PostUrl = (fileKey: string) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}?fileKey=${fileKey}`);
};

export const getS3Url = (fileKey: string) => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}?fileKey=${fileKey}`);
};

export const getS3HeadUrl = (fileKeys: string) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}?fileKeys=${fileKeys}&type=head`
  );
};

export const getS3CopyUrl = (fileKey: string, source: string) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}?fileKey=${fileKey}&type=copy&source=${source}`
  );
};
export const getS3ObjectHead = (url: string) => {
  return axios.head(url);
};

export const hitCopy = (url: string) => {
  return axios.get(url);
};
export const getNode = (node: any, store: any) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}?type=node&node=${node}&store=${store}&db=yes`
  );
};

export const auth = (userName: any, password: any) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}?type=auth&userName=${userName}&password=${password}&db=yes`
  );
};

export const getStores = () => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}?type=stores&db=yes`);
};

export const updateNode = (nodeData: Node) => {
  return axios.put(
    `${process.env.REACT_APP_BASE_URL}?type=node&db=yes`,
    nodeData
  );
};

export const addWeek = (wk: string, node: string, store: string) => {
  return axios.put(
    `${
      process.env.REACT_APP_BASE_URL
    }?type=week&db=yes&node=${node}&store=${store}&week=${wk.replace(" ", "_")}`
  );
};

export const deleteNodeWeek = (wk: string, node: string, store: string) => {
  return axios.delete(
    `${
      process.env.REACT_APP_BASE_URL
    }?type=week&db=yes&node=${node}&store=${store}&week=${wk.replace(" ", "_")}`
  );
};

export const uopdateImageMeta = (
  node: string,
  store: string,
  fileKey: string,
  user: string,
  posCode: string,
  posElement: string
) => {
  return axios.put(
    `${process.env.REACT_APP_BASE_URL}?type=fileMeta&db=yes&node=${node}&store=${store}&fileKey=${fileKey}&user=${user}`,
    { posCode, posElement }
  );
};
export async function uploadPhoto(
  s3Url: string,
  photoFile: any,
  metadata: any
): Promise<any> {
  return axios.put(s3Url, photoFile, {
    headers: {
      "Content-Type": metadata.contentType,
    },
  });
}
