import FortyKModel from "./FortyKModel/FortyKModel";
const SVG = (props: any) => {

  const onCircleClick = (data: any)=>{
    props.onCircleClick(data);
  }
    const getSVG = ()=>{
      switch(props.store){
        case '40KModel':
          return <FortyKModel fileUploadList={props.fileUploadList} onCircleClick={onCircleClick}></FortyKModel>;
        default: 
          return <span> NO Layout Found</span>;
      }
        
    }
  return (<>{getSVG()}</>);
};

export default SVG;