import React, { useEffect, useState } from "react";
import { Div } from './styles'
const FortyKModel = ({ fileUploadList, ...props }: any) => {
  // const [hoverNode, setHoverNode] = useState<any>();
  // useEffect(() => {
  //   const cbox = document.querySelectorAll("circle") || [];

  //   for (let i=0; i< cbox.length; i++) {
  //       cbox[i].addEventListener("mouseenter",  (e)=> {
  //         // (e?.target as SVGCircleElement).setAttribute('title', (e?.target as SVGCircleElement).id)
  //         let title = document.createElement('title');
  //         title.innerHTML = (e?.target as SVGCircleElement).id;
  //         (e?.target as SVGCircleElement).children.length ==0 && (e?.target as SVGCircleElement).appendChild(title);
  //         // setHoverNode((e?.target as SVGCircleElement).id)
  //     });

  //     cbox[i].addEventListener("mouseleave",  (e)=> {
  //       setHoverNode(null)
  //   });
  //   }
  // }, [])
  useEffect(() => {
  }, [fileUploadList]);

  const getCheckedSVG = (): any => {
    return fileUploadList.filter((d: any) => !d.isUploaded).map((d: any, i: number) => {
      const { x, y } = d.nodeData;
      return <circle key={i} cx={x} r='9' cy={y} id="checkSVG" width="24px" height="24px" fill="#000000" />

    })
  }
  const onCircleClick = (data: any) => {
    const { event, node } = data;
    // console.log(event.target.attributes, event.target.attributes['cx'], event.target.attributes['cy']);
    props.onCircleClick({ node, x: event.target.attributes['cx'].value, y: event.target.attributes['cy'].value });
  }
  return (<>
    <Div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -5 1516.61 953.5">

        <g id="layout">
          <g id="fixture_fill">
            <g id="LWPOLYLINE">
              <rect className="cls-2" x="372.36" y="227.62" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-2">
              <rect className="cls-2" x="373.59" y="216.7" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-3">
              <rect className="cls-2" x="373.59" y="291.46" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-4">
              <rect className="cls-2" x="372.36" y="259.54" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-5">
              <rect className="cls-2" x="311.68" y="227.62" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-6">
              <rect className="cls-2" x="312.91" y="216.69" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-7">
              <rect className="cls-2" x="312.91" y="291.46" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-8">
              <rect className="cls-2" x="311.68" y="259.54" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-9">
              <rect className="cls-2" x="323.73" y="100.72" width="31.28" height="9.9" />
            </g>
            <g id="LWPOLYLINE-10">
              <rect className="cls-2" x="312.81" y="91.4" width="10.28" height="17.99" />
            </g>
            <g id="LWPOLYLINE-11">
              <rect className="cls-2" x="387.57" y="91.4" width="10.28" height="17.99" />
            </g>
            <g id="LWPOLYLINE-12">
              <rect className="cls-2" x="355.65" y="100.72" width="31.28" height="9.9" />
            </g>
            <g id="LWPOLYLINE-13">
              <rect className="cls-2" x="502.44" y="99.93" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-14">
              <rect className="cls-2" x="503.66" y="89.01" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-15">
              <rect className="cls-2" x="503.66" y="163.77" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-16">
              <rect className="cls-2" x="502.44" y="131.85" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-17">
              <rect className="cls-2" x="502.44" y="227.62" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-18">
              <rect className="cls-2" x="503.66" y="216.69" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-19">
              <rect className="cls-2" x="503.66" y="291.46" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-20">
              <rect className="cls-2" x="502.44" y="259.54" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-21">
              <rect className="cls-2" x="899.92" y="99.84" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-22">
              <rect className="cls-2" x="901.15" y="88.91" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-23">
              <rect className="cls-2" x="901.15" y="163.68" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-24">
              <rect className="cls-2" x="899.92" y="131.76" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-25">
              <rect className="cls-2" x="1032.36" y="99.84" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-26">
              <rect className="cls-2" x="1033.59" y="88.91" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-27">
              <rect className="cls-2" x="1033.59" y="163.68" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-28">
              <rect className="cls-2" x="1032.36" y="131.76" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-29">
              <rect className="cls-2" x="1161.93" y="99.84" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-30">
              <rect className="cls-2" x="1163.15" y="88.91" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-31">
              <rect className="cls-2" x="1163.15" y="163.68" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-32">
              <rect className="cls-2" x="1161.93" y="131.76" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-33">
              <rect className="cls-2" x="1161.93" y="227.52" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-34">
              <rect className="cls-2" x="1163.15" y="216.6" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-35">
              <rect className="cls-2" x="1163.15" y="291.36" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-36">
              <rect className="cls-2" x="1161.93" y="259.44" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-37">
              <rect className="cls-2" x="1292" y="227.52" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-38">
              <rect className="cls-2" x="1293.23" y="216.6" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-39">
              <rect className="cls-2" x="1293.23" y="291.36" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-40">
              <rect className="cls-2" x="1292" y="259.44" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-41">
              <rect className="cls-2" x="1292" y="99.84" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-42">
              <rect className="cls-2" x="1293.23" y="88.92" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-43">
              <rect className="cls-2" x="1293.23" y="163.69" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-44">
              <rect className="cls-2" x="1292" y="131.77" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-45">
              <rect className="cls-2" x="1422.08" y="103.79" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-46">
              <rect className="cls-2" x="1423.31" y="92.87" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-47">
              <rect className="cls-2" x="1423.31" y="167.63" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-48">
              <rect className="cls-2" x="1422.08" y="135.71" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-49">
              <rect className="cls-2" x="437.09" y="259.54" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-50">
              <rect className="cls-2" x="437.09" y="227.62" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-51">
              <rect className="cls-2" x="437.09" y="195.7" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-52">
              <rect className="cls-2" x="437.09" y="163.78" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-53">
              <rect className="cls-2" x="437.09" y="131.86" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-54">
              <rect className="cls-2" x="437.09" y="99.94" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-55">
              <rect className="cls-2" x="448.28" y="259.54" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-56">
              <rect className="cls-2" x="448.28" y="227.62" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-57">
              <rect className="cls-2" x="448.28" y="195.7" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-58">
              <rect className="cls-2" x="448.28" y="163.78" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-59">
              <rect className="cls-2" x="448.28" y="131.86" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-60">
              <rect className="cls-2" x="448.28" y="99.94" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-61">
              <rect className="cls-2" x="438.32" y="291.46" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-62">
              <rect className="cls-2" x="438.32" y="89.02" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-63">
              <rect className="cls-2" x="834.03" y="259.45" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-64">
              <rect className="cls-2" x="834.03" y="227.53" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-65">
              <rect className="cls-2" x="834.03" y="195.61" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-66">
              <rect className="cls-2" x="834.03" y="163.69" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-67">
              <rect className="cls-2" x="834.03" y="131.77" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-68">
              <rect className="cls-2" x="834.03" y="99.84" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-69">
              <rect className="cls-2" x="845.22" y="259.45" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-70">
              <rect className="cls-2" x="845.22" y="227.53" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-71">
              <rect className="cls-2" x="845.22" y="195.61" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-72">
              <rect className="cls-2" x="845.22" y="163.69" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-73">
              <rect className="cls-2" x="845.22" y="131.77" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-74">
              <rect className="cls-2" x="845.22" y="99.84" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-75">
              <rect className="cls-2" x="835.26" y="291.37" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-76">
              <rect className="cls-2" x="835.26" y="88.92" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-77">
              <rect className="cls-2" x="966.5" y="259.45" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-78">
              <rect className="cls-2" x="966.5" y="227.53" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-79">
              <rect className="cls-2" x="966.5" y="195.61" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-80">
              <rect className="cls-2" x="966.5" y="163.69" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-81">
              <rect className="cls-2" x="966.5" y="131.77" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-82">
              <rect className="cls-2" x="966.5" y="99.84" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-83">
              <rect className="cls-2" x="977.69" y="259.45" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-84">
              <rect className="cls-2" x="977.69" y="227.53" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-85">
              <rect className="cls-2" x="977.69" y="195.61" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-86">
              <rect className="cls-2" x="977.69" y="163.69" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-87">
              <rect className="cls-2" x="977.69" y="131.77" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-88">
              <rect className="cls-2" x="977.69" y="99.84" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-89">
              <rect className="cls-2" x="967.73" y="291.37" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-90">
              <rect className="cls-2" x="967.73" y="88.92" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-91">
              <rect className="cls-2" x="1031.91" y="227.53" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-92">
              <rect className="cls-2" x="1033.13" y="216.6" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-93">
              <rect className="cls-2" x="1033.13" y="291.37" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-94">
              <rect className="cls-2" x="1031.91" y="259.45" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-95">
              <rect className="cls-2" x="1096.32" y="257.38" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-96">
              <rect className="cls-2" x="1096.32" y="225.46" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-97">
              <rect className="cls-2" x="1096.32" y="193.54" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-98">
              <rect className="cls-2" x="1096.32" y="161.62" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-99">
              <rect className="cls-2" x="1096.32" y="129.7" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-100">
              <rect className="cls-2" x="1096.32" y="97.78" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-101">
              <rect className="cls-2" x="1107.5" y="257.38" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-102">
              <rect className="cls-2" x="1107.5" y="225.46" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-103">
              <rect className="cls-2" x="1107.5" y="193.54" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-104">
              <rect className="cls-2" x="1107.5" y="161.62" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-105">
              <rect className="cls-2" x="1107.5" y="129.7" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-106">
              <rect className="cls-2" x="1107.5" y="97.78" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-107">
              <rect className="cls-2" x="1097.55" y="289.3" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-108">
              <rect className="cls-2" x="1226.66" y="259.45" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-109">
              <rect className="cls-2" x="1226.66" y="227.53" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-110">
              <rect className="cls-2" x="1226.66" y="195.61" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-111">
              <rect className="cls-2" x="1226.66" y="163.69" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-112">
              <rect className="cls-2" x="1226.66" y="131.77" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-113">
              <rect className="cls-2" x="1226.66" y="99.84" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-114">
              <rect className="cls-2" x="1237.85" y="259.45" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-115">
              <rect className="cls-2" x="1237.85" y="227.53" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-116">
              <rect className="cls-2" x="1237.85" y="195.61" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-117">
              <rect className="cls-2" x="1237.85" y="163.69" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-118">
              <rect className="cls-2" x="1237.85" y="131.77" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-119">
              <rect className="cls-2" x="1237.85" y="99.84" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-120">
              <rect className="cls-2" x="1227.89" y="291.37" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-121">
              <rect className="cls-2" x="1227.89" y="88.92" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-122">
              <rect className="cls-2" x="1356.74" y="259.45" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-123">
              <rect className="cls-2" x="1356.74" y="227.53" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-124">
              <rect className="cls-2" x="1356.74" y="195.61" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-125">
              <rect className="cls-2" x="1356.74" y="163.69" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-126">
              <rect className="cls-2" x="1356.74" y="131.77" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-127">
              <rect className="cls-2" x="1356.74" y="99.84" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-128">
              <rect className="cls-2" x="1367.92" y="259.45" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-129">
              <rect className="cls-2" x="1367.92" y="227.53" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-130">
              <rect className="cls-2" x="1367.92" y="195.61" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-131">
              <rect className="cls-2" x="1367.92" y="163.69" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-132">
              <rect className="cls-2" x="1367.92" y="131.77" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-133">
              <rect className="cls-2" x="1367.92" y="99.84" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-134">
              <rect className="cls-2" x="1357.96" y="291.37" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-135">
              <rect className="cls-2" x="1357.96" y="88.92" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-136">
              <rect className="cls-2" x="567.17" y="257.29" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-137">
              <rect className="cls-2" x="567.17" y="225.37" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-138">
              <rect className="cls-2" x="567.17" y="193.45" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-139">
              <rect className="cls-2" x="567.17" y="161.53" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-140">
              <rect className="cls-2" x="578.35" y="257.29" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-141">
              <rect className="cls-2" x="578.35" y="225.37" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-142">
              <rect className="cls-2" x="578.35" y="193.45" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-143">
              <rect className="cls-2" x="578.35" y="161.53" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-144">
              <rect className="cls-2" x="568.4" y="289.21" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-145">
              <rect className="cls-2" x="632.22" y="259.54" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-146">
              <rect className="cls-2" x="632.22" y="227.62" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-147">
              <rect className="cls-2" x="632.22" y="195.7" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-148">
              <rect className="cls-2" x="643.41" y="259.54" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-149">
              <rect className="cls-2" x="643.41" y="227.62" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-150">
              <rect className="cls-2" x="643.41" y="195.7" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-151">
              <rect className="cls-2" x="633.45" y="291.46" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-152">
              <rect className="cls-2" x="633.45" y="184.78" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-153">
              <rect className="cls-2" x="697.25" y="259.45" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-154">
              <rect className="cls-2" x="697.25" y="227.53" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-155">
              <rect className="cls-2" x="697.25" y="195.61" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-156">
              <rect className="cls-2" x="697.25" y="163.69" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-157">
              <rect className="cls-2" x="708.43" y="259.45" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-158">
              <rect className="cls-2" x="708.43" y="227.53" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-159">
              <rect className="cls-2" x="708.43" y="195.61" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-160">
              <rect className="cls-2" x="708.43" y="163.69" width="9.9" height="31.28" />
            </g>
            <g id="LWPOLYLINE-161">
              <rect className="cls-2" x="698.47" y="291.37" width="17.99" height="10.28" />
            </g>
            <g id="LWPOLYLINE-162">
              <rect className="cls-2" x="759.96" y="200.75" width="32.44" height="7.97" />
            </g>
            <g id="LWPOLYLINE-163">
              <rect className="cls-2" x="759.96" y="210" width="32.44" height="7.97" />
            </g>
            <g id="LWPOLYLINE-164">
              <rect className="cls-2" x="759.96" y="150.13" width="32.44" height="7.97" />
            </g>
            <g id="LWPOLYLINE-165">
              <rect className="cls-2" x="759.96" y="159.39" width="32.44" height="7.97" />
            </g>
            <g id="LWPOLYLINE-166">
              <rect className="cls-2" x="759.96" y="101.56" width="32.44" height="7.97" />
            </g>
            <g id="LWPOLYLINE-167">
              <rect className="cls-2" x="759.96" y="110.81" width="32.44" height="7.97" />
            </g>
            <g id="LWPOLYLINE-168">
              <rect className="cls-2" x="372.57" y="144.6" width="17.02" height="7.32" />
            </g>
            <g id="LWPOLYLINE-169">
              <rect className="cls-2" x="372.57" y="153.21" width="17.02" height="7.32" />
            </g>
            <g id="LWPOLYLINE-170">
              <rect className="cls-2" x="381.07" y="160.53" width="17.02" height="7.32" />
            </g>
            <g id="LWPOLYLINE-171">
              <rect className="cls-2" x="381.07" y="169.14" width="17.02" height="7.32" />
            </g>
            <g id="LWPOLYLINE-172">
              <rect className="cls-2" x="291.61" y="157.75" width="49.02" height="17.57" />
            </g>
            <g id="LWPOLYLINE-173">
              <rect className="cls-2" x="294.4" y="176.47" width="42.61" height="5.7" />
            </g>
            <g id="LWPOLYLINE-174">
              <rect className="cls-2" x="294.59" y="151.08" width="42.61" height="5.7" />
            </g>
            <rect className="cls-11" x="363.69" y="393.83" width="17.49" height="28.3" />
            <polygon className="cls-2"
              points="332.15 534.45 317.75 534.45 317.75 523.08 314.66 523.08 314.66 534.45 314.66 536.08 314.66 562.75 332.15 562.75 332.15 534.45" />
            <rect className="cls-2" x="369.54" y="351.25" width="20.56" height="7.97" />
            <rect className="cls-2" x="369.54" y="360.5" width="20.56" height="7.97" />
            <rect className="cls-2" x="318.14" y="351.25" width="20.56" height="7.97" />
            <rect className="cls-2" x="318.14" y="360.5" width="20.56" height="7.97" />
            <g id="LWPOLYLINE-175">
              <rect className="cls-2" x="1494.95" y="298.83" width="10.72" height="55.73" />
            </g>
            <g id="LWPOLYLINE-176">
              <rect className="cls-2" x="1493.53" y="263.95" width="10.22" height="31.28" />
            </g>
            <g id="LWPOLYLINE-177">
              <rect className="cls-2" x="1493.53" y="232.03" width="10.22" height="31.28" />
            </g>
            <g id="LWPOLYLINE-178">
              <rect className="cls-2" x="1493.53" y="200.11" width="10.22" height="31.28" />
            </g>
            <g id="LWPOLYLINE-179">
              <rect className="cls-2" x="1493.53" y="168.19" width="10.22" height="31.28" />
            </g>
            <g id="LWPOLYLINE-180">
              <rect className="cls-2" x="1493.53" y="136.27" width="10.22" height="31.28" />
            </g>
            <g id="LWPOLYLINE-181">
              <rect className="cls-2" x="1493.53" y="104.35" width="10.22" height="31.28" />
            </g>
            <g id="LWPOLYLINE-182">
              <rect className="cls-2" x="1493.53" y="72.43" width="10.22" height="31.28" />
            </g>
            <g id="LWPOLYLINE-183">
              <rect className="cls-2" x="1493.53" y="40.51" width="10.22" height="31.28" />
            </g>
            <g id="LWPOLYLINE-184">
              <rect className="cls-11" x="98.61" y="620.39" width="47.26" height="54.97" />
            </g>
            <g id="LWPOLYLINE-185">
              <rect className="cls-11" x="99.88" y="649.67" width="12.85" height="12.85" />
            </g>
            <g id="LWPOLYLINE-186">
              <rect className="cls-11" x="111.45" y="622.94" width="12.85" height="12.85" />
            </g>
            <g id="LWPOLYLINE-187">
              <rect className="cls-11" x="125.73" y="628.4" width="2.76" height="33.57" />
            </g>
            <g id="LWPOLYLINE-188">
              <rect className="cls-11" x="132.25" y="628.39" width="1.71" height="33.58" />
            </g>
            <g id="LWPOLYLINE-189">
              <rect className="cls-11" x="128.49" y="637.56" width="3.77" height="10.65" />
            </g>
            <g id="LWPOLYLINE-190">
              <rect className="cls-2" x="1432.32" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-191">
              <rect className="cls-2" x="1400.4" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-192">
              <rect className="cls-2" x="1368.48" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-193">
              <rect className="cls-2" x="1336.56" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-194">
              <rect className="cls-2" x="1212.06" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-195">
              <rect className="cls-2" x="1180.14" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-196">
              <rect className="cls-2" x="1148.22" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-197">
              <rect className="cls-2" x="1116.3" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-198">
              <rect className="cls-2" x="1084.38" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-199">
              <rect className="cls-2" x="1052.46" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-200">
              <rect className="cls-2" x="1020.54" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-201">
              <rect className="cls-2" x="988.62" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-202">
              <rect className="cls-2" x="956.7" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-203">
              <rect className="cls-2" x="924.78" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-204">
              <rect className="cls-2" x="892.86" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-205">
              <rect className="cls-2" x="860.94" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-206">
              <rect className="cls-2" x="829.02" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-207">
              <rect className="cls-2" x="797.1" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-208">
              <rect className="cls-2" x="765.18" y="29.03" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-209">
              <rect className="cls-2" x="733.26" y="29" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-210">
              <rect className="cls-2" x="701.34" y="29" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-211">
              <rect className="cls-2" x="541.73" y="29" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-212">
              <rect className="cls-2" x="509.81" y="29" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-213">
              <rect className="cls-2" x="477.89" y="29" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-214">
              <rect className="cls-2" x="445.97" y="29" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-215">
              <rect className="cls-2" x="414.05" y="29" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-216">
              <rect className="cls-2" x="382.13" y="29" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-217">
              <rect className="cls-2" x="350.21" y="29" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-218">
              <rect className="cls-2" x="318.29" y="29" width="31.28" height="10.22" />
            </g>
            <g id="LWPOLYLINE-219">
              <rect className="cls-2" x="1464.24" y="29.03" width="15.86" height="10.22" />
            </g>
            <polyline className="cls-11"
              points="128.49 661.49 130.47 661.49 130.47 674.34 117.62 674.34 117.62 661.49 125.73 661.49" />
            <polyline className="cls-2"
              points="323.73 90.17 355.01 90.17 355.01 100.07 323.73 100.07 323.73 90.17 386.93 90.17 386.93 100.07 355.65 100.07 355.65 90.17 386.93 90.17" />
            <polyline className="cls-11"
              points="354.52 172.96 341.67 172.96 341.67 160.11 354.52 160.11 354.52 172.96 341.67 172.96 341.67 160.11" />
            <polyline className="cls-2"
              points="332.13 227.62 332.13 258.89 322.22 258.89 322.22 227.62 332.13 227.62" />
            <polyline className="cls-2"
              points="392.81 227.62 392.81 258.9 382.9 258.9 382.9 227.62 392.81 227.62" />
            <polyline className="cls-2"
              points="332.13 290.81 322.22 290.81 322.22 259.54 332.13 259.54 332.13 290.81" />
            <polyline className="cls-2"
              points="392.81 290.82 382.9 290.82 382.9 259.54 392.81 259.54 392.81 290.82" />
            <polyline className="cls-11"
              points="394.21 347.39 314.54 347.39 314.54 426.3 394.21 426.3 394.21 347.39" />
            <polyline className="cls-2"
              points="522.88 227.62 522.88 258.89 512.98 258.89 512.98 227.62 522.88 227.62 522.88 290.81 512.98 290.81 512.98 259.54 522.88 259.54 522.88 290.81" />
            <polyline className="cls-2"
              points="522.88 99.93 522.88 131.21 512.98 131.21 512.98 99.93 522.88 99.93 522.88 163.13 512.98 163.13 512.98 131.85 522.88 131.85 522.88 163.13" />
            <polyline className="cls-7"
              points="633.33 79.73 633.33 30.33 582.75 30.33 582.75 102.76 633.33 102.76 633.33 99.29 634.92 99.29 634.92 103.53 637.08 103.53 637.08 106.52 579 106.51 579 26.58 691.05 26.58 691.06 106.51 688.07 106.51 688.07 29.56 637.49 29.56 637.49 79.73" />
            <polyline className="cls-2"
              points="920.37 163.04 920.37 131.76 910.47 131.76 910.47 163.04 920.37 163.04 920.37 99.84 910.47 99.84 910.47 131.11 920.37 131.11 920.37 99.84" />
            <path className="cls-10"
              d="m755.07,285.26v6.56-.23c-.06.84.57,1.58,1.41,1.64h0s17.73,0,17.73,0c.83-.05,1.46-.77,1.41-1.6v.18-6.3" />
            <path className="cls-10"
              d="m775.63,264.97v-6.02c.08-.86-.56-1.62-1.41-1.69h-17.55c-.83-.05-1.55.58-1.6,1.41v6.57l.77-.28h33.15c.83.05,1.46.76,1.41,1.6v17.55c-.08.86-.84,1.49-1.69,1.41h-32.87.18c-.83.05-1.55-.58-1.6-1.41v-17.73h0c.06-.84.8-1.48,1.64-1.41" />
            <polyline className="cls-10" points="792.21 266.16 790.93 266.16 790.93 266.81" />
            <polyline className="cls-2"
              points="1052.35 227.53 1052.35 258.8 1042.45 258.8 1042.45 227.53 1052.35 227.53 1052.35 290.72 1042.45 290.72 1042.45 259.45 1052.35 259.45 1052.35 290.72" />
            <polyline className="cls-2"
              points="1052.81 99.84 1052.81 131.11 1042.91 131.11 1042.91 99.84 1052.81 99.84 1052.81 163.04 1042.91 163.04 1042.91 131.76 1052.81 131.76 1052.81 163.04" />
            <polyline className="cls-2"
              points="1182.37 163.04 1182.37 131.76 1172.47 131.76 1172.47 163.04 1182.37 163.04 1182.37 99.84 1172.47 99.84 1172.47 131.11 1182.37 131.11 1182.37 99.84" />
            <polyline className="cls-2"
              points="1182.37 290.72 1182.37 259.44 1172.47 259.44 1172.47 290.72 1182.37 290.72 1182.37 227.52 1172.47 227.52 1172.47 258.8 1182.37 258.8 1182.37 227.52" />
            <polyline className="cls-2"
              points="1312.45 163.04 1312.45 131.77 1302.55 131.77 1302.55 163.04 1312.45 163.04 1312.45 99.84 1302.55 99.84 1302.55 131.12 1312.45 131.12 1312.45 99.84" />
            <polyline className="cls-2"
              points="1312.45 290.72 1312.45 259.44 1302.55 259.44 1302.55 290.72 1312.45 290.72 1312.45 227.52 1302.55 227.52 1302.55 258.8 1312.45 258.8 1312.45 227.52" />
            <polyline className="cls-2"
              points="1442.53 103.79 1442.53 135.07 1432.63 135.07 1432.63 103.79 1442.53 103.79 1442.53 166.99 1432.63 166.99 1432.63 135.71 1442.53 135.71 1442.53 166.99" />
            <rect className="cls-11" x="317.75" y="390.96" width="32.13" height="32.13" />
            <g id="LWPOLYLINE-220">
              <rect className="cls-2" x="792.21" y="266.16" width="7.32" height="17.02" />
            </g>
            <g id="LWPOLYLINE-221">
              <rect className="cls-2" x="901.02" y="240.69" width="7.97" height="20.48" />
            </g>
            <g id="LWPOLYLINE-222">
              <rect className="cls-2" x="910.27" y="240.69" width="7.97" height="20.48" />
            </g>
            <g id="LWPOLYLINE-223">
              <rect className="cls-2" x="901.02" y="217.03" width="7.97" height="20.48" />
            </g>
            <g id="LWPOLYLINE-224">
              <rect className="cls-2" x="910.27" y="217.03" width="7.97" height="20.48" />
            </g>
            <g id="LWPOLYLINE-225">
              <rect className="cls-2" x="1423.07" y="240.69" width="7.97" height="20.48" />
            </g>
            <g id="LWPOLYLINE-226">
              <rect className="cls-2" x="1432.32" y="240.69" width="7.97" height="20.48" />
            </g>
            <g id="LWPOLYLINE-227">
              <rect className="cls-2" x="1423.07" y="217.03" width="7.97" height="20.48" />
            </g>
            <g id="LWPOLYLINE-228">
              <rect className="cls-2" x="1432.32" y="217.03" width="7.97" height="20.48" />
            </g>
            <g id="nu3">
              <polyline className="cls-11"
                points="930.19 302.93 889.07 302.93 889.07 210.41 930.19 210.41 930.19 302.93" />
              <g id="LWPOLYLINE-229">
                <rect className="cls-11" x="893.57" y="265.02" width="32.13" height="32.13" />
              </g>
              <polyline className="cls-11"
                points="1452.24 302.93 1411.12 302.93 1411.12 210.41 1452.24 210.41 1452.24 302.93" />
              <g id="LWPOLYLINE-230">
                <rect className="cls-11" x="1415.62" y="265.02" width="32.13" height="32.13" />
              </g>
            </g>
          </g>
          <g id="fixture_internal">
            <g id="LINE">
              <line className="cls-9" x1="372.36" y1="258.9" x2="372.36" y2="259.54" />
            </g>
            <g id="LINE-2">
              <line className="cls-9" x1="392.81" y1="258.9" x2="392.81" y2="259.54" />
            </g>
            <g id="LINE-3">
              <line className="cls-9" x1="392.81" y1="259.54" x2="382.9" y2="259.54" />
            </g>
            <g id="LINE-4">
              <line className="cls-9" x1="382.9" y1="259.54" x2="382.9" y2="290.82" />
            </g>
            <g id="LINE-5">
              <line className="cls-9" x1="382.9" y1="290.82" x2="392.81" y2="290.82" />
            </g>
            <g id="LINE-6">
              <line className="cls-9" x1="392.81" y1="291.46" x2="372.36" y2="291.46" />
            </g>
            <g id="LINE-7">
              <line className="cls-9" x1="372.36" y1="291.46" x2="372.36" y2="290.82" />
            </g>
            <g id="LINE-8">
              <line className="cls-9" x1="372.36" y1="290.82" x2="382.26" y2="290.82" />
            </g>
            <g id="LINE-9">
              <line className="cls-9" x1="382.26" y1="290.82" x2="382.26" y2="259.54" />
            </g>
            <g id="LINE-10">
              <line className="cls-9" x1="382.26" y1="259.54" x2="372.36" y2="259.54" />
            </g>
            <g id="LINE-11">
              <line className="cls-9" x1="392.81" y1="258.9" x2="382.9" y2="258.9" />
            </g>
            <g id="LINE-12">
              <line className="cls-9" x1="382.9" y1="258.9" x2="382.9" y2="227.62" />
            </g>
            <g id="LINE-13">
              <line className="cls-9" x1="382.9" y1="227.62" x2="392.81" y2="227.62" />
            </g>
            <g id="LINE-14">
              <line className="cls-9" x1="392.81" y1="227.62" x2="392.81" y2="226.98" />
            </g>
            <g id="LINE-15">
              <line className="cls-9" x1="392.81" y1="226.98" x2="372.36" y2="226.98" />
            </g>
            <g id="LINE-16">
              <line className="cls-9" x1="372.36" y1="226.98" x2="372.36" y2="227.62" />
            </g>
            <g id="LINE-17">
              <line className="cls-9" x1="372.36" y1="227.62" x2="382.26" y2="227.62" />
            </g>
            <g id="LINE-18">
              <line className="cls-9" x1="382.26" y1="227.62" x2="382.26" y2="258.9" />
            </g>
            <g id="LINE-19">
              <line className="cls-9" x1="382.26" y1="258.9" x2="372.36" y2="258.9" />
            </g>
            <g id="LINE-20">
              <line className="cls-9" x1="392.81" y1="290.82" x2="392.81" y2="291.46" />
            </g>
            <g id="LINE-21">
              <line className="cls-9" x1="332.13" y1="258.89" x2="322.22" y2="258.89" />
            </g>
            <g id="LINE-22">
              <line className="cls-9" x1="322.22" y1="258.89" x2="322.22" y2="227.62" />
            </g>
            <g id="LINE-23">
              <line className="cls-9" x1="322.22" y1="227.62" x2="332.13" y2="227.62" />
            </g>
            <g id="LINE-24">
              <line className="cls-9" x1="332.13" y1="227.62" x2="332.13" y2="226.97" />
            </g>
            <g id="LINE-25">
              <line className="cls-9" x1="332.13" y1="226.97" x2="311.68" y2="226.97" />
            </g>
            <g id="LINE-26">
              <line className="cls-9" x1="311.68" y1="226.97" x2="311.68" y2="227.62" />
            </g>
            <g id="LINE-27">
              <line className="cls-9" x1="311.68" y1="227.62" x2="321.58" y2="227.62" />
            </g>
            <g id="LINE-28">
              <line className="cls-9" x1="321.58" y1="227.62" x2="321.58" y2="258.89" />
            </g>
            <g id="LINE-29">
              <line className="cls-9" x1="321.58" y1="258.89" x2="311.68" y2="258.89" />
            </g>
            <g id="LINE-30">
              <line className="cls-9" x1="332.13" y1="258.89" x2="332.13" y2="259.54" />
            </g>
            <g id="LINE-31">
              <line className="cls-9" x1="332.13" y1="259.54" x2="322.22" y2="259.54" />
            </g>
            <g id="LINE-32">
              <line className="cls-9" x1="322.22" y1="259.54" x2="322.22" y2="290.81" />
            </g>
            <g id="LINE-33">
              <line className="cls-9" x1="322.22" y1="290.81" x2="332.13" y2="290.81" />
            </g>
            <g id="LINE-34">
              <line className="cls-9" x1="332.13" y1="291.46" x2="311.68" y2="291.46" />
            </g>
            <g id="LINE-35">
              <line className="cls-9" x1="311.68" y1="291.46" x2="311.68" y2="290.81" />
            </g>
            <g id="LINE-36">
              <line className="cls-9" x1="311.68" y1="290.81" x2="321.58" y2="290.81" />
            </g>
            <g id="LINE-37">
              <line className="cls-9" x1="321.58" y1="290.81" x2="321.58" y2="259.54" />
            </g>
            <g id="LINE-38">
              <line className="cls-9" x1="321.58" y1="259.54" x2="311.68" y2="259.54" />
            </g>
            <g id="LINE-39">
              <line className="cls-9" x1="311.68" y1="258.89" x2="311.68" y2="259.54" />
            </g>
            <g id="LINE-40">
              <line className="cls-9" x1="332.13" y1="290.81" x2="332.13" y2="291.46" />
            </g>
            <g id="LINE-41">
              <line className="cls-9" x1="355.01" y1="90.17" x2="355.01" y2="100.07" />
            </g>
            <g id="LINE-42">
              <line className="cls-9" x1="355.01" y1="100.07" x2="323.73" y2="100.07" />
            </g>
            <g id="LINE-43">
              <line className="cls-9" x1="323.73" y1="100.07" x2="323.73" y2="90.17" />
            </g>
            <g id="LINE-44">
              <line className="cls-9" x1="323.73" y1="90.17" x2="323.09" y2="90.17" />
            </g>
            <g id="LINE-45">
              <line className="cls-9" x1="323.09" y1="90.17" x2="323.09" y2="110.62" />
            </g>
            <g id="LINE-46">
              <line className="cls-9" x1="323.09" y1="110.62" x2="323.73" y2="110.62" />
            </g>
            <g id="LINE-47">
              <line className="cls-9" x1="323.73" y1="110.62" x2="323.73" y2="100.72" />
            </g>
            <g id="LINE-48">
              <line className="cls-9" x1="323.73" y1="100.72" x2="355.01" y2="100.72" />
            </g>
            <g id="LINE-49">
              <line className="cls-9" x1="355.01" y1="100.72" x2="355.01" y2="110.62" />
            </g>
            <g id="LINE-50">
              <line className="cls-9" x1="355.01" y1="90.17" x2="355.65" y2="90.17" />
            </g>
            <g id="LINE-51">
              <line className="cls-9" x1="355.65" y1="90.17" x2="355.65" y2="100.07" />
            </g>
            <g id="LINE-52">
              <line className="cls-9" x1="355.65" y1="100.07" x2="386.93" y2="100.07" />
            </g>
            <g id="LINE-53">
              <line className="cls-9" x1="386.93" y1="100.07" x2="386.93" y2="90.17" />
            </g>
            <g id="LINE-54">
              <line className="cls-9" x1="387.57" y1="90.17" x2="387.57" y2="110.62" />
            </g>
            <g id="LINE-55">
              <line className="cls-9" x1="387.57" y1="110.62" x2="386.93" y2="110.62" />
            </g>
            <g id="LINE-56">
              <line className="cls-9" x1="386.93" y1="110.62" x2="386.93" y2="100.72" />
            </g>
            <g id="LINE-57">
              <line className="cls-9" x1="386.93" y1="100.72" x2="355.65" y2="100.72" />
            </g>
            <g id="LINE-58">
              <line className="cls-9" x1="355.65" y1="100.72" x2="355.65" y2="110.62" />
            </g>
            <g id="LINE-59">
              <line className="cls-9" x1="355.01" y1="110.62" x2="355.65" y2="110.62" />
            </g>
            <g id="LINE-60">
              <line className="cls-9" x1="386.93" y1="90.17" x2="387.57" y2="90.17" />
            </g>
            <g id="LINE-61">
              <line className="cls-9" x1="522.88" y1="131.21" x2="512.98" y2="131.21" />
            </g>
            <g id="LINE-62">
              <line className="cls-9" x1="512.98" y1="131.21" x2="512.98" y2="99.93" />
            </g>
            <g id="LINE-63">
              <line className="cls-9" x1="512.98" y1="99.93" x2="522.88" y2="99.93" />
            </g>
            <g id="LINE-64">
              <line className="cls-9" x1="522.88" y1="99.93" x2="522.88" y2="99.29" />
            </g>
            <g id="LINE-65">
              <line className="cls-9" x1="522.88" y1="99.29" x2="502.44" y2="99.29" />
            </g>
            <g id="LINE-66">
              <line className="cls-9" x1="502.44" y1="99.29" x2="502.44" y2="99.93" />
            </g>
            <g id="LINE-67">
              <line className="cls-9" x1="502.44" y1="99.93" x2="512.34" y2="99.93" />
            </g>
            <g id="LINE-68">
              <line className="cls-9" x1="512.34" y1="99.93" x2="512.34" y2="131.21" />
            </g>
            <g id="LINE-69">
              <line className="cls-9" x1="512.34" y1="131.21" x2="502.44" y2="131.21" />
            </g>
            <g id="LINE-70">
              <line className="cls-9" x1="522.88" y1="131.21" x2="522.88" y2="131.85" />
            </g>
            <g id="LINE-71">
              <line className="cls-9" x1="522.88" y1="131.85" x2="512.98" y2="131.85" />
            </g>
            <g id="LINE-72">
              <line className="cls-9" x1="512.98" y1="131.85" x2="512.98" y2="163.13" />
            </g>
            <g id="LINE-73">
              <line className="cls-9" x1="512.98" y1="163.13" x2="522.88" y2="163.13" />
            </g>
            <g id="LINE-74">
              <line className="cls-9" x1="522.88" y1="163.77" x2="502.44" y2="163.77" />
            </g>
            <g id="LINE-75">
              <line className="cls-9" x1="502.44" y1="163.77" x2="502.44" y2="163.13" />
            </g>
            <g id="LINE-76">
              <line className="cls-9" x1="502.44" y1="163.13" x2="512.34" y2="163.13" />
            </g>
            <g id="LINE-77">
              <line className="cls-9" x1="512.34" y1="163.13" x2="512.34" y2="131.85" />
            </g>
            <g id="LINE-78">
              <line className="cls-9" x1="512.34" y1="131.85" x2="502.44" y2="131.85" />
            </g>
            <g id="LINE-79">
              <line className="cls-9" x1="502.44" y1="131.21" x2="502.44" y2="131.85" />
            </g>
            <g id="LINE-80">
              <line className="cls-9" x1="522.88" y1="163.13" x2="522.88" y2="163.77" />
            </g>
            <g id="LINE-81">
              <line className="cls-9" x1="522.88" y1="258.89" x2="512.98" y2="258.89" />
            </g>
            <g id="LINE-82">
              <line className="cls-9" x1="512.98" y1="258.89" x2="512.98" y2="227.62" />
            </g>
            <g id="LINE-83">
              <line className="cls-9" x1="512.98" y1="227.62" x2="522.88" y2="227.62" />
            </g>
            <g id="LINE-84">
              <line className="cls-9" x1="522.88" y1="227.62" x2="522.88" y2="226.97" />
            </g>
            <g id="LINE-85">
              <line className="cls-9" x1="522.88" y1="226.97" x2="502.44" y2="226.97" />
            </g>
            <g id="LINE-86">
              <line className="cls-9" x1="502.44" y1="226.97" x2="502.44" y2="227.62" />
            </g>
            <g id="LINE-87">
              <line className="cls-9" x1="502.44" y1="227.62" x2="512.34" y2="227.62" />
            </g>
            <g id="LINE-88">
              <line className="cls-9" x1="512.34" y1="227.62" x2="512.34" y2="258.89" />
            </g>
            <g id="LINE-89">
              <line className="cls-9" x1="512.34" y1="258.89" x2="502.44" y2="258.89" />
            </g>
            <g id="LINE-90">
              <line className="cls-9" x1="522.88" y1="258.89" x2="522.88" y2="259.54" />
            </g>
            <g id="LINE-91">
              <line className="cls-9" x1="522.88" y1="259.54" x2="512.98" y2="259.54" />
            </g>
            <g id="LINE-92">
              <line className="cls-9" x1="512.98" y1="259.54" x2="512.98" y2="290.81" />
            </g>
            <g id="LINE-93">
              <line className="cls-9" x1="512.98" y1="290.81" x2="522.88" y2="290.81" />
            </g>
            <g id="LINE-94">
              <line className="cls-9" x1="522.88" y1="291.46" x2="502.44" y2="291.46" />
            </g>
            <g id="LINE-95">
              <line className="cls-9" x1="502.44" y1="291.46" x2="502.44" y2="290.81" />
            </g>
            <g id="LINE-96">
              <line className="cls-9" x1="502.44" y1="290.81" x2="512.34" y2="290.81" />
            </g>
            <g id="LINE-97">
              <line className="cls-9" x1="512.34" y1="290.81" x2="512.34" y2="259.54" />
            </g>
            <g id="LINE-98">
              <line className="cls-9" x1="512.34" y1="259.54" x2="502.44" y2="259.54" />
            </g>
            <g id="LINE-99">
              <line className="cls-9" x1="502.44" y1="258.89" x2="502.44" y2="259.54" />
            </g>
            <g id="LINE-100">
              <line className="cls-9" x1="522.88" y1="290.81" x2="522.88" y2="291.46" />
            </g>
            <g id="LINE-101">
              <line className="cls-9" x1="920.37" y1="131.11" x2="910.47" y2="131.11" />
            </g>
            <g id="LINE-102">
              <line className="cls-9" x1="910.47" y1="131.11" x2="910.47" y2="99.84" />
            </g>
            <g id="LINE-103">
              <line className="cls-9" x1="910.47" y1="99.84" x2="920.37" y2="99.84" />
            </g>
            <g id="LINE-104">
              <line className="cls-9" x1="920.37" y1="99.84" x2="920.37" y2="99.19" />
            </g>
            <g id="LINE-105">
              <line className="cls-9" x1="920.37" y1="99.19" x2="899.92" y2="99.19" />
            </g>
            <g id="LINE-106">
              <line className="cls-9" x1="899.92" y1="99.19" x2="899.92" y2="99.84" />
            </g>
            <g id="LINE-107">
              <line className="cls-9" x1="899.92" y1="99.84" x2="909.82" y2="99.84" />
            </g>
            <g id="LINE-108">
              <line className="cls-9" x1="909.82" y1="99.84" x2="909.82" y2="131.11" />
            </g>
            <g id="LINE-109">
              <line className="cls-9" x1="909.82" y1="131.11" x2="899.92" y2="131.11" />
            </g>
            <g id="LINE-110">
              <line className="cls-9" x1="920.37" y1="131.11" x2="920.37" y2="131.76" />
            </g>
            <g id="LINE-111">
              <line className="cls-9" x1="920.37" y1="131.76" x2="910.47" y2="131.76" />
            </g>
            <g id="LINE-112">
              <line className="cls-9" x1="910.47" y1="131.76" x2="910.47" y2="163.04" />
            </g>
            <g id="LINE-113">
              <line className="cls-9" x1="910.47" y1="163.04" x2="920.37" y2="163.04" />
            </g>
            <g id="LINE-114">
              <line className="cls-9" x1="920.37" y1="163.68" x2="899.92" y2="163.68" />
            </g>
            <g id="LINE-115">
              <line className="cls-9" x1="899.92" y1="163.68" x2="899.92" y2="163.04" />
            </g>
            <g id="LINE-116">
              <line className="cls-9" x1="899.92" y1="163.04" x2="909.82" y2="163.04" />
            </g>
            <g id="LINE-117">
              <line className="cls-9" x1="909.82" y1="163.04" x2="909.82" y2="131.76" />
            </g>
            <g id="LINE-118">
              <line className="cls-9" x1="909.82" y1="131.76" x2="899.92" y2="131.76" />
            </g>
            <g id="LINE-119">
              <line className="cls-9" x1="899.92" y1="131.11" x2="899.92" y2="131.76" />
            </g>
            <g id="LINE-120">
              <line className="cls-9" x1="920.37" y1="163.04" x2="920.37" y2="163.68" />
            </g>
            <g id="LINE-121">
              <line className="cls-9" x1="1052.81" y1="131.11" x2="1042.91" y2="131.11" />
            </g>
            <g id="LINE-122">
              <line className="cls-9" x1="1042.91" y1="131.11" x2="1042.91" y2="99.84" />
            </g>
            <g id="LINE-123">
              <line className="cls-9" x1="1042.91" y1="99.84" x2="1052.81" y2="99.84" />
            </g>
            <g id="LINE-124">
              <line className="cls-9" x1="1052.81" y1="99.84" x2="1052.81" y2="99.19" />
            </g>
            <g id="LINE-125">
              <line className="cls-9" x1="1052.81" y1="99.19" x2="1032.36" y2="99.19" />
            </g>
            <g id="LINE-126">
              <line className="cls-9" x1="1032.36" y1="99.19" x2="1032.36" y2="99.84" />
            </g>
            <g id="LINE-127">
              <line className="cls-9" x1="1032.36" y1="99.84" x2="1042.27" y2="99.84" />
            </g>
            <g id="LINE-128">
              <line className="cls-9" x1="1042.27" y1="99.84" x2="1042.27" y2="131.11" />
            </g>
            <g id="LINE-129">
              <line className="cls-9" x1="1042.27" y1="131.11" x2="1032.36" y2="131.11" />
            </g>
            <g id="LINE-130">
              <line className="cls-9" x1="1052.81" y1="131.11" x2="1052.81" y2="131.76" />
            </g>
            <g id="LINE-131">
              <line className="cls-9" x1="1052.81" y1="131.76" x2="1042.91" y2="131.76" />
            </g>
            <g id="LINE-132">
              <line className="cls-9" x1="1042.91" y1="131.76" x2="1042.91" y2="163.04" />
            </g>
            <g id="LINE-133">
              <line className="cls-9" x1="1042.91" y1="163.04" x2="1052.81" y2="163.04" />
            </g>
            <g id="LINE-134">
              <line className="cls-9" x1="1052.81" y1="163.68" x2="1032.36" y2="163.68" />
            </g>
            <g id="LINE-135">
              <line className="cls-9" x1="1032.36" y1="163.68" x2="1032.36" y2="163.04" />
            </g>
            <g id="LINE-136">
              <line className="cls-9" x1="1032.36" y1="163.04" x2="1042.27" y2="163.04" />
            </g>
            <g id="LINE-137">
              <line className="cls-9" x1="1042.27" y1="163.04" x2="1042.27" y2="131.76" />
            </g>
            <g id="LINE-138">
              <line className="cls-9" x1="1042.27" y1="131.76" x2="1032.36" y2="131.76" />
            </g>
            <g id="LINE-139">
              <line className="cls-9" x1="1032.36" y1="131.11" x2="1032.36" y2="131.76" />
            </g>
            <g id="LINE-140">
              <line className="cls-9" x1="1052.81" y1="163.04" x2="1052.81" y2="163.68" />
            </g>
            <g id="LINE-141">
              <line className="cls-9" x1="1182.37" y1="131.11" x2="1172.47" y2="131.11" />
            </g>
            <g id="LINE-142">
              <line className="cls-9" x1="1172.47" y1="131.11" x2="1172.47" y2="99.84" />
            </g>
            <g id="LINE-143">
              <line className="cls-9" x1="1172.47" y1="99.84" x2="1182.37" y2="99.84" />
            </g>
            <g id="LINE-144">
              <line className="cls-9" x1="1182.37" y1="99.84" x2="1182.37" y2="99.19" />
            </g>
            <g id="LINE-145">
              <line className="cls-9" x1="1182.37" y1="99.19" x2="1161.93" y2="99.19" />
            </g>
            <g id="LINE-146">
              <line className="cls-9" x1="1161.93" y1="99.19" x2="1161.93" y2="99.84" />
            </g>
            <g id="LINE-147">
              <line className="cls-9" x1="1161.93" y1="99.84" x2="1171.83" y2="99.84" />
            </g>
            <g id="LINE-148">
              <line className="cls-9" x1="1171.83" y1="99.84" x2="1171.83" y2="131.11" />
            </g>
            <g id="LINE-149">
              <line className="cls-9" x1="1171.83" y1="131.11" x2="1161.93" y2="131.11" />
            </g>
            <g id="LINE-150">
              <line className="cls-9" x1="1182.37" y1="131.11" x2="1182.37" y2="131.76" />
            </g>
            <g id="LINE-151">
              <line className="cls-9" x1="1182.37" y1="131.76" x2="1172.47" y2="131.76" />
            </g>
            <g id="LINE-152">
              <line className="cls-9" x1="1172.47" y1="131.76" x2="1172.47" y2="163.04" />
            </g>
            <g id="LINE-153">
              <line className="cls-9" x1="1172.47" y1="163.04" x2="1182.37" y2="163.04" />
            </g>
            <g id="LINE-154">
              <line className="cls-9" x1="1182.37" y1="163.68" x2="1161.93" y2="163.68" />
            </g>
            <g id="LINE-155">
              <line className="cls-9" x1="1161.93" y1="163.68" x2="1161.93" y2="163.04" />
            </g>
            <g id="LINE-156">
              <line className="cls-9" x1="1161.93" y1="163.04" x2="1171.83" y2="163.04" />
            </g>
            <g id="LINE-157">
              <line className="cls-9" x1="1171.83" y1="163.04" x2="1171.83" y2="131.76" />
            </g>
            <g id="LINE-158">
              <line className="cls-9" x1="1171.83" y1="131.76" x2="1161.93" y2="131.76" />
            </g>
            <g id="LINE-159">
              <line className="cls-9" x1="1161.93" y1="131.11" x2="1161.93" y2="131.76" />
            </g>
            <g id="LINE-160">
              <line className="cls-9" x1="1182.37" y1="163.04" x2="1182.37" y2="163.68" />
            </g>
            <g id="LINE-161">
              <line className="cls-9" x1="1182.37" y1="258.8" x2="1172.47" y2="258.8" />
            </g>
            <g id="LINE-162">
              <line className="cls-9" x1="1172.47" y1="258.8" x2="1172.47" y2="227.52" />
            </g>
            <g id="LINE-163">
              <line className="cls-9" x1="1172.47" y1="227.52" x2="1182.37" y2="227.52" />
            </g>
            <g id="LINE-164">
              <line className="cls-9" x1="1182.37" y1="227.52" x2="1182.37" y2="226.88" />
            </g>
            <g id="LINE-165">
              <line className="cls-9" x1="1182.37" y1="226.88" x2="1161.93" y2="226.88" />
            </g>
            <g id="LINE-166">
              <line className="cls-9" x1="1161.93" y1="226.88" x2="1161.93" y2="227.52" />
            </g>
            <g id="LINE-167">
              <line className="cls-9" x1="1161.93" y1="227.52" x2="1171.83" y2="227.52" />
            </g>
            <g id="LINE-168">
              <line className="cls-9" x1="1171.83" y1="227.52" x2="1171.83" y2="258.8" />
            </g>
            <g id="LINE-169">
              <line className="cls-9" x1="1171.83" y1="258.8" x2="1161.93" y2="258.8" />
            </g>
            <g id="LINE-170">
              <line className="cls-9" x1="1182.37" y1="258.8" x2="1182.37" y2="259.44" />
            </g>
            <g id="LINE-171">
              <line className="cls-9" x1="1182.37" y1="259.44" x2="1172.47" y2="259.44" />
            </g>
            <g id="LINE-172">
              <line className="cls-9" x1="1172.47" y1="259.44" x2="1172.47" y2="290.72" />
            </g>
            <g id="LINE-173">
              <line className="cls-9" x1="1172.47" y1="290.72" x2="1182.37" y2="290.72" />
            </g>
            <g id="LINE-174">
              <line className="cls-9" x1="1182.37" y1="291.36" x2="1161.93" y2="291.36" />
            </g>
            <g id="LINE-175">
              <line className="cls-9" x1="1161.93" y1="291.36" x2="1161.93" y2="290.72" />
            </g>
            <g id="LINE-176">
              <line className="cls-9" x1="1161.93" y1="290.72" x2="1171.83" y2="290.72" />
            </g>
            <g id="LINE-177">
              <line className="cls-9" x1="1171.83" y1="290.72" x2="1171.83" y2="259.44" />
            </g>
            <g id="LINE-178">
              <line className="cls-9" x1="1171.83" y1="259.44" x2="1161.93" y2="259.44" />
            </g>
            <g id="LINE-179">
              <line className="cls-9" x1="1161.93" y1="258.8" x2="1161.93" y2="259.44" />
            </g>
            <g id="LINE-180">
              <line className="cls-9" x1="1182.37" y1="290.72" x2="1182.37" y2="291.36" />
            </g>
            <g id="LINE-181">
              <line className="cls-9" x1="1312.45" y1="258.8" x2="1302.55" y2="258.8" />
            </g>
            <g id="LINE-182">
              <line className="cls-9" x1="1302.55" y1="258.8" x2="1302.55" y2="227.52" />
            </g>
            <g id="LINE-183">
              <line className="cls-9" x1="1302.55" y1="227.52" x2="1312.45" y2="227.52" />
            </g>
            <g id="LINE-184">
              <line className="cls-9" x1="1312.45" y1="227.52" x2="1312.45" y2="226.88" />
            </g>
            <g id="LINE-185">
              <line className="cls-9" x1="1312.45" y1="226.88" x2="1292" y2="226.88" />
            </g>
            <g id="LINE-186">
              <line className="cls-9" x1="1292" y1="226.88" x2="1292" y2="227.52" />
            </g>
            <g id="LINE-187">
              <line className="cls-9" x1="1292" y1="227.52" x2="1301.91" y2="227.52" />
            </g>
            <g id="LINE-188">
              <line className="cls-9" x1="1301.91" y1="227.52" x2="1301.91" y2="258.8" />
            </g>
            <g id="LINE-189">
              <line className="cls-9" x1="1301.91" y1="258.8" x2="1292" y2="258.8" />
            </g>
            <g id="LINE-190">
              <line className="cls-9" x1="1312.45" y1="258.8" x2="1312.45" y2="259.44" />
            </g>
            <g id="LINE-191">
              <line className="cls-9" x1="1312.45" y1="259.44" x2="1302.55" y2="259.44" />
            </g>
            <g id="LINE-192">
              <line className="cls-9" x1="1302.55" y1="259.44" x2="1302.55" y2="290.72" />
            </g>
            <g id="LINE-193">
              <line className="cls-9" x1="1302.55" y1="290.72" x2="1312.45" y2="290.72" />
            </g>
            <g id="LINE-194">
              <line className="cls-9" x1="1312.45" y1="291.36" x2="1292" y2="291.36" />
            </g>
            <g id="LINE-195">
              <line className="cls-9" x1="1292" y1="291.36" x2="1292" y2="290.72" />
            </g>
            <g id="LINE-196">
              <line className="cls-9" x1="1292" y1="290.72" x2="1301.91" y2="290.72" />
            </g>
            <g id="LINE-197">
              <line className="cls-9" x1="1301.91" y1="290.72" x2="1301.91" y2="259.44" />
            </g>
            <g id="LINE-198">
              <line className="cls-9" x1="1301.91" y1="259.44" x2="1292" y2="259.44" />
            </g>
            <g id="LINE-199">
              <line className="cls-9" x1="1292" y1="258.8" x2="1292" y2="259.44" />
            </g>
            <g id="LINE-200">
              <line className="cls-9" x1="1312.45" y1="290.72" x2="1312.45" y2="291.36" />
            </g>
            <g id="LINE-201">
              <line className="cls-9" x1="1312.45" y1="131.12" x2="1302.55" y2="131.12" />
            </g>
            <g id="LINE-202">
              <line className="cls-9" x1="1302.55" y1="131.12" x2="1302.55" y2="99.84" />
            </g>
            <g id="LINE-203">
              <line className="cls-9" x1="1302.55" y1="99.84" x2="1312.45" y2="99.84" />
            </g>
            <g id="LINE-204">
              <line className="cls-9" x1="1312.45" y1="99.84" x2="1312.45" y2="99.2" />
            </g>
            <g id="LINE-205">
              <line className="cls-9" x1="1312.45" y1="99.2" x2="1292" y2="99.2" />
            </g>
            <g id="LINE-206">
              <line className="cls-9" x1="1292" y1="99.2" x2="1292" y2="99.84" />
            </g>
            <g id="LINE-207">
              <line className="cls-9" x1="1292" y1="99.84" x2="1301.91" y2="99.84" />
            </g>
            <g id="LINE-208">
              <line className="cls-9" x1="1301.91" y1="99.84" x2="1301.91" y2="131.12" />
            </g>
            <g id="LINE-209">
              <line className="cls-9" x1="1301.91" y1="131.12" x2="1292" y2="131.12" />
            </g>
            <g id="LINE-210">
              <line className="cls-9" x1="1312.45" y1="131.12" x2="1312.45" y2="131.77" />
            </g>
            <g id="LINE-211">
              <line className="cls-9" x1="1312.45" y1="131.77" x2="1302.55" y2="131.77" />
            </g>
            <g id="LINE-212">
              <line className="cls-9" x1="1302.55" y1="131.77" x2="1302.55" y2="163.04" />
            </g>
            <g id="LINE-213">
              <line className="cls-9" x1="1302.55" y1="163.04" x2="1312.45" y2="163.04" />
            </g>
            <g id="LINE-214">
              <line className="cls-9" x1="1312.45" y1="163.69" x2="1292" y2="163.69" />
            </g>
            <g id="LINE-215">
              <line className="cls-9" x1="1292" y1="163.69" x2="1292" y2="163.04" />
            </g>
            <g id="LINE-216">
              <line className="cls-9" x1="1292" y1="163.04" x2="1301.91" y2="163.04" />
            </g>
            <g id="LINE-217">
              <line className="cls-9" x1="1301.91" y1="163.04" x2="1301.91" y2="131.77" />
            </g>
            <g id="LINE-218">
              <line className="cls-9" x1="1301.91" y1="131.77" x2="1292" y2="131.77" />
            </g>
            <g id="LINE-219">
              <line className="cls-9" x1="1292" y1="131.12" x2="1292" y2="131.77" />
            </g>
            <g id="LINE-220">
              <line className="cls-9" x1="1312.45" y1="163.04" x2="1312.45" y2="163.69" />
            </g>
            <g id="LINE-221">
              <line className="cls-9" x1="1442.53" y1="135.07" x2="1432.63" y2="135.07" />
            </g>
            <g id="LINE-222">
              <line className="cls-9" x1="1432.63" y1="135.07" x2="1432.63" y2="103.79" />
            </g>
            <g id="LINE-223">
              <line className="cls-9" x1="1432.63" y1="103.79" x2="1442.53" y2="103.79" />
            </g>
            <g id="LINE-224">
              <line className="cls-9" x1="1442.53" y1="103.79" x2="1442.53" y2="103.15" />
            </g>
            <g id="LINE-225">
              <line className="cls-9" x1="1442.53" y1="103.15" x2="1422.08" y2="103.15" />
            </g>
            <g id="LINE-226">
              <line className="cls-9" x1="1422.08" y1="103.15" x2="1422.08" y2="103.79" />
            </g>
            <g id="LINE-227">
              <line className="cls-9" x1="1422.08" y1="103.79" x2="1431.98" y2="103.79" />
            </g>
            <g id="LINE-228">
              <line className="cls-9" x1="1431.98" y1="103.79" x2="1431.98" y2="135.07" />
            </g>
            <g id="LINE-229">
              <line className="cls-9" x1="1431.98" y1="135.07" x2="1422.08" y2="135.07" />
            </g>
            <g id="LINE-230">
              <line className="cls-9" x1="1442.53" y1="135.07" x2="1442.53" y2="135.71" />
            </g>
            <g id="LINE-231">
              <line className="cls-9" x1="1442.53" y1="135.71" x2="1432.63" y2="135.71" />
            </g>
            <g id="LINE-232">
              <line className="cls-9" x1="1432.63" y1="135.71" x2="1432.63" y2="166.99" />
            </g>
            <g id="LINE-233">
              <line className="cls-9" x1="1432.63" y1="166.99" x2="1442.53" y2="166.99" />
            </g>
            <g id="LINE-234">
              <line className="cls-9" x1="1442.53" y1="167.63" x2="1422.08" y2="167.63" />
            </g>
            <g id="LINE-235">
              <line className="cls-9" x1="1422.08" y1="167.63" x2="1422.08" y2="166.99" />
            </g>
            <g id="LINE-236">
              <line className="cls-9" x1="1422.08" y1="166.99" x2="1431.98" y2="166.99" />
            </g>
            <g id="LINE-237">
              <line className="cls-9" x1="1431.98" y1="166.99" x2="1431.98" y2="135.71" />
            </g>
            <g id="LINE-238">
              <line className="cls-9" x1="1431.98" y1="135.71" x2="1422.08" y2="135.71" />
            </g>
            <g id="LINE-239">
              <line className="cls-9" x1="1422.08" y1="135.07" x2="1422.08" y2="135.71" />
            </g>
            <g id="LINE-240">
              <line className="cls-9" x1="1442.53" y1="166.99" x2="1442.53" y2="167.63" />
            </g>
            <g id="LWPOLYLINE-231">
              <polygon className="cls-9"
                points="437.09 99.3 458.18 99.3 458.18 99.94 448.28 99.94 448.28 131.22 458.18 131.22 458.18 131.86 448.28 131.86 448.28 163.14 458.18 163.14 458.18 163.78 448.28 163.78 448.28 195.06 458.18 195.06 458.18 195.7 448.28 195.7 448.28 226.98 458.18 226.98 458.18 227.62 448.28 227.62 448.28 258.9 458.18 258.9 458.18 259.54 448.28 259.54 448.28 290.82 458.18 290.82 458.38 291.46 437.09 291.46 437.09 290.82 446.99 290.82 446.99 259.54 437.09 259.54 437.09 258.9 446.99 258.9 446.99 227.62 437.09 227.62 437.09 226.98 446.99 226.98 446.99 195.7 437.09 195.7 437.09 195.06 446.99 195.06 446.99 163.78 437.09 163.78 437.09 163.14 446.99 163.14 446.99 131.86 437.09 131.86 437.09 131.22 446.99 131.22 446.99 99.94 437.09 99.94 437.09 99.3" />
            </g>
            <g id="LWPOLYLINE-232">
              <polygon className="cls-9"
                points="834.03 99.2 855.12 99.2 855.12 99.84 845.22 99.84 845.22 131.12 855.12 131.12 855.12 131.77 845.22 131.77 845.22 163.04 855.12 163.04 855.12 163.69 845.22 163.69 845.22 194.96 855.12 194.96 855.12 195.61 845.22 195.61 845.22 226.88 855.12 226.88 855.12 227.53 845.22 227.53 845.22 258.8 855.12 258.8 855.12 259.45 845.22 259.45 845.22 290.72 855.12 290.72 855.32 291.37 834.03 291.37 834.03 290.72 843.94 290.72 843.94 259.45 834.03 259.45 834.03 258.8 843.94 258.8 843.94 227.53 834.03 227.53 834.03 226.88 843.94 226.88 843.94 195.61 834.03 195.61 834.03 194.96 843.94 194.96 843.94 163.69 834.03 163.69 834.03 163.04 843.94 163.04 843.94 131.77 834.03 131.77 834.03 131.12 843.94 131.12 843.94 99.84 834.03 99.84 834.03 99.2" />
            </g>
            <g id="LWPOLYLINE-233">
              <polygon className="cls-9"
                points="966.5 99.2 987.59 99.2 987.59 99.84 977.69 99.84 977.69 131.12 987.59 131.12 987.59 131.77 977.69 131.77 977.69 163.04 987.59 163.04 987.59 163.69 977.69 163.69 977.69 194.96 987.59 194.96 987.59 195.61 977.69 195.61 977.69 226.88 987.59 226.88 987.59 227.53 977.69 227.53 977.69 258.8 987.59 258.8 987.59 259.45 977.69 259.45 977.69 290.72 987.59 290.72 987.79 291.37 966.5 291.37 966.5 290.72 976.41 290.72 976.41 259.45 966.5 259.45 966.5 258.8 976.41 258.8 976.41 227.53 966.5 227.53 966.5 226.88 976.41 226.88 976.41 195.61 966.5 195.61 966.5 194.96 976.41 194.96 976.41 163.69 966.5 163.69 966.5 163.04 976.41 163.04 976.41 131.77 966.5 131.77 966.5 131.12 976.41 131.12 976.41 99.84 966.5 99.84 966.5 99.2" />
            </g>
            <g id="LINE-241">
              <line className="cls-9" x1="1052.35" y1="258.8" x2="1042.45" y2="258.8" />
            </g>
            <g id="LINE-242">
              <line className="cls-9" x1="1042.45" y1="258.8" x2="1042.45" y2="227.53" />
            </g>
            <g id="LINE-243">
              <line className="cls-9" x1="1042.45" y1="227.53" x2="1052.35" y2="227.53" />
            </g>
            <g id="LINE-244">
              <line className="cls-9" x1="1052.35" y1="227.53" x2="1052.35" y2="226.88" />
            </g>
            <g id="LINE-245">
              <line className="cls-9" x1="1052.35" y1="226.88" x2="1031.91" y2="226.88" />
            </g>
            <g id="LINE-246">
              <line className="cls-9" x1="1031.91" y1="226.88" x2="1031.91" y2="227.53" />
            </g>
            <g id="LINE-247">
              <line className="cls-9" x1="1031.91" y1="227.53" x2="1041.81" y2="227.53" />
            </g>
            <g id="LINE-248">
              <line className="cls-9" x1="1041.81" y1="227.53" x2="1041.81" y2="258.8" />
            </g>
            <g id="LINE-249">
              <line className="cls-9" x1="1041.81" y1="258.8" x2="1031.91" y2="258.8" />
            </g>
            <g id="LINE-250">
              <line className="cls-9" x1="1052.35" y1="258.8" x2="1052.35" y2="259.45" />
            </g>
            <g id="LINE-251">
              <line className="cls-9" x1="1052.35" y1="259.45" x2="1042.45" y2="259.45" />
            </g>
            <g id="LINE-252">
              <line className="cls-9" x1="1042.45" y1="259.45" x2="1042.45" y2="290.72" />
            </g>
            <g id="LINE-253">
              <line className="cls-9" x1="1042.45" y1="290.72" x2="1052.35" y2="290.72" />
            </g>
            <g id="LINE-254">
              <line className="cls-9" x1="1052.35" y1="291.37" x2="1031.91" y2="291.37" />
            </g>
            <g id="LINE-255">
              <line className="cls-9" x1="1031.91" y1="291.37" x2="1031.91" y2="290.72" />
            </g>
            <g id="LINE-256">
              <line className="cls-9" x1="1031.91" y1="290.72" x2="1041.81" y2="290.72" />
            </g>
            <g id="LINE-257">
              <line className="cls-9" x1="1041.81" y1="290.72" x2="1041.81" y2="259.45" />
            </g>
            <g id="LINE-258">
              <line className="cls-9" x1="1041.81" y1="259.45" x2="1031.91" y2="259.45" />
            </g>
            <g id="LINE-259">
              <line className="cls-9" x1="1031.91" y1="258.8" x2="1031.91" y2="259.45" />
            </g>
            <g id="LINE-260">
              <line className="cls-9" x1="1052.35" y1="290.72" x2="1052.35" y2="291.37" />
            </g>
            <g id="LWPOLYLINE-234">
              <polygon className="cls-9"
                points="1096.32 97.14 1117.41 97.14 1117.41 97.78 1107.5 97.78 1107.5 129.06 1117.41 129.06 1117.41 129.7 1107.5 129.7 1107.5 160.98 1117.41 160.98 1117.41 161.62 1107.5 161.62 1107.5 192.9 1117.41 192.9 1117.41 193.54 1107.5 193.54 1107.5 224.82 1117.41 224.82 1117.41 225.46 1107.5 225.46 1107.5 256.74 1117.41 256.74 1117.41 257.38 1107.5 257.38 1107.5 288.66 1117.41 288.66 1117.6 289.3 1096.32 289.3 1096.32 288.66 1106.22 288.66 1106.22 257.38 1096.32 257.38 1096.32 256.74 1106.22 256.74 1106.22 225.46 1096.32 225.46 1096.32 224.82 1106.22 224.82 1106.22 193.54 1096.32 193.54 1096.32 192.9 1106.22 192.9 1106.22 161.62 1096.32 161.62 1096.32 160.98 1106.22 160.98 1106.22 129.7 1096.32 129.7 1096.32 129.06 1106.22 129.06 1106.22 97.78 1096.32 97.78 1096.32 97.14" />
            </g>
            <g id="LWPOLYLINE-235">
              <polygon className="cls-9"
                points="1226.66 99.2 1247.75 99.2 1247.75 99.84 1237.85 99.84 1237.85 131.12 1247.75 131.12 1247.75 131.77 1237.85 131.77 1237.85 163.04 1247.75 163.04 1247.75 163.69 1237.85 163.69 1237.85 194.96 1247.75 194.96 1247.75 195.61 1237.85 195.61 1237.85 226.88 1247.75 226.88 1247.75 227.53 1237.85 227.53 1237.85 258.8 1247.75 258.8 1247.75 259.45 1237.85 259.45 1237.85 290.72 1247.75 290.72 1247.94 291.37 1226.66 291.37 1226.66 290.72 1236.56 290.72 1236.56 259.45 1226.66 259.45 1226.66 258.8 1236.56 258.8 1236.56 227.53 1226.66 227.53 1226.66 226.88 1236.56 226.88 1236.56 195.61 1226.66 195.61 1226.66 194.96 1236.56 194.96 1236.56 163.69 1226.66 163.69 1226.66 163.04 1236.56 163.04 1236.56 131.77 1226.66 131.77 1226.66 131.12 1236.56 131.12 1236.56 99.84 1226.66 99.84 1226.66 99.2" />
            </g>
            <g id="LWPOLYLINE-236">
              <polygon className="cls-9"
                points="1356.74 99.2 1377.83 99.2 1377.83 99.84 1367.92 99.84 1367.92 131.12 1377.83 131.12 1377.83 131.77 1367.92 131.77 1367.92 163.04 1377.83 163.04 1377.83 163.69 1367.92 163.69 1367.92 194.96 1377.83 194.96 1377.83 195.61 1367.92 195.61 1367.92 226.88 1377.83 226.88 1377.83 227.53 1367.92 227.53 1367.92 258.8 1377.83 258.8 1377.83 259.45 1367.92 259.45 1367.92 290.72 1377.83 290.72 1378.02 291.37 1356.74 291.37 1356.74 290.72 1366.64 290.72 1366.64 259.45 1356.74 259.45 1356.74 258.8 1366.64 258.8 1366.64 227.53 1356.74 227.53 1356.74 226.88 1366.64 226.88 1366.64 195.61 1356.74 195.61 1356.74 194.96 1366.64 194.96 1366.64 163.69 1356.74 163.69 1356.74 163.04 1366.64 163.04 1366.64 131.77 1356.74 131.77 1356.74 131.12 1366.64 131.12 1366.64 99.84 1356.74 99.84 1356.74 99.2" />
            </g>
            <g id="LWPOLYLINE-237">
              <polygon className="cls-9"
                points="588.26 288.57 588.26 289.21 567.17 289.21 567.17 288.57 577.07 288.57 577.07 257.29 567.17 257.29 567.17 256.65 577.07 256.65 577.07 225.37 567.17 225.37 567.17 224.73 577.07 224.73 577.07 193.45 567.17 193.45 567.17 192.81 577.07 192.81 577.07 161.53 567.17 161.53 567.17 160.89 588.25 160.89 588.26 161.53 578.35 161.53 578.35 192.81 588.26 192.81 588.26 193.45 578.35 193.45 578.35 224.73 588.26 224.73 588.26 225.37 578.35 225.37 578.35 256.65 588.26 256.65 588.26 257.29 578.35 257.29 578.35 288.57 588.26 288.57" />
            </g>
            <g id="LINE-261">
              <line className="cls-9" x1="653.31" y1="290.82" x2="653.31" y2="291.46" />
            </g>
            <g id="LINE-262">
              <line className="cls-9" x1="653.31" y1="291.46" x2="632.22" y2="291.46" />
            </g>
            <g id="LINE-263">
              <line className="cls-9" x1="632.22" y1="291.46" x2="632.22" y2="290.82" />
            </g>
            <g id="LINE-264">
              <line className="cls-9" x1="632.22" y1="290.82" x2="642.12" y2="290.82" />
            </g>
            <g id="LINE-265">
              <line className="cls-9" x1="642.12" y1="290.82" x2="642.12" y2="259.54" />
            </g>
            <g id="LINE-266">
              <line className="cls-9" x1="642.12" y1="259.54" x2="632.22" y2="259.54" />
            </g>
            <g id="LINE-267">
              <line className="cls-9" x1="632.22" y1="259.54" x2="632.22" y2="258.9" />
            </g>
            <g id="LINE-268">
              <line className="cls-9" x1="632.22" y1="258.9" x2="642.12" y2="258.9" />
            </g>
            <g id="LINE-269">
              <line className="cls-9" x1="642.12" y1="258.9" x2="642.12" y2="227.62" />
            </g>
            <g id="LINE-270">
              <line className="cls-9" x1="642.12" y1="227.62" x2="632.22" y2="227.62" />
            </g>
            <g id="LINE-271">
              <line className="cls-9" x1="632.22" y1="227.62" x2="632.22" y2="226.98" />
            </g>
            <g id="LINE-272">
              <line className="cls-9" x1="632.22" y1="226.98" x2="642.12" y2="226.98" />
            </g>
            <g id="LINE-273">
              <line className="cls-9" x1="642.12" y1="226.98" x2="642.12" y2="195.7" />
            </g>
            <g id="LINE-274">
              <line className="cls-9" x1="642.12" y1="195.7" x2="632.22" y2="195.7" />
            </g>
            <g id="LINE-275">
              <line className="cls-9" x1="632.22" y1="195.7" x2="632.22" y2="195.06" />
            </g>
            <g id="LINE-276">
              <line className="cls-9" x1="632.22" y1="195.06" x2="653.31" y2="195.06" />
            </g>
            <g id="LINE-277">
              <line className="cls-9" x1="653.31" y1="195.06" x2="653.31" y2="195.7" />
            </g>
            <g id="LINE-278">
              <line className="cls-9" x1="653.31" y1="195.7" x2="643.41" y2="195.7" />
            </g>
            <g id="LINE-279">
              <line className="cls-9" x1="643.41" y1="195.7" x2="643.41" y2="226.98" />
            </g>
            <g id="LINE-280">
              <line className="cls-9" x1="643.41" y1="226.98" x2="653.31" y2="226.98" />
            </g>
            <g id="LINE-281">
              <line className="cls-9" x1="653.31" y1="226.98" x2="653.31" y2="227.62" />
            </g>
            <g id="LINE-282">
              <line className="cls-9" x1="653.31" y1="227.62" x2="643.41" y2="227.62" />
            </g>
            <g id="LINE-283">
              <line className="cls-9" x1="643.41" y1="227.62" x2="643.41" y2="258.9" />
            </g>
            <g id="LINE-284">
              <line className="cls-9" x1="643.41" y1="258.9" x2="653.31" y2="258.9" />
            </g>
            <g id="LINE-285">
              <line className="cls-9" x1="653.31" y1="258.9" x2="653.31" y2="259.54" />
            </g>
            <g id="LINE-286">
              <line className="cls-9" x1="653.31" y1="259.54" x2="643.41" y2="259.54" />
            </g>
            <g id="LINE-287">
              <line className="cls-9" x1="643.41" y1="259.54" x2="643.41" y2="290.82" />
            </g>
            <g id="LINE-288">
              <line className="cls-9" x1="643.41" y1="290.82" x2="653.31" y2="290.82" />
            </g>
            <g id="LWPOLYLINE-238">
              <polygon className="cls-9"
                points="718.34 290.72 718.34 291.37 697.25 291.37 697.25 290.72 707.15 290.72 707.15 259.45 697.25 259.45 697.25 258.8 707.15 258.8 707.15 227.53 697.25 227.53 697.25 226.88 707.15 226.88 707.15 195.61 697.25 195.61 697.25 194.96 707.15 194.96 707.15 163.69 697.25 163.69 697.25 163.04 718.32 163.04 718.34 163.69 708.43 163.69 708.43 194.96 718.34 194.96 718.34 195.61 708.43 195.61 708.43 226.88 718.34 226.88 718.34 227.53 708.43 227.53 708.43 258.8 718.34 258.8 718.34 259.45 708.43 259.45 708.43 290.72 718.34 290.72" />
            </g>
            <g id="LWPOLYLINE-239">
              <rect className="cls-9" x="759.96" y="208.72" width="32.44" height="1.29" />
            </g>
            <g id="LWPOLYLINE-240">
              <rect className="cls-9" x="759.96" y="158.1" width="32.44" height="1.29" />
            </g>
            <g id="LWPOLYLINE-241">
              <rect className="cls-9" x="759.96" y="109.53" width="32.44" height="1.29" />
            </g>
            <g id="LWPOLYLINE-242">
              <rect className="cls-9" x="372.57" y="151.92" width="17.02" height="1.29" />
            </g>
            <g id="LWPOLYLINE-243">
              <rect className="cls-9" x="381.07" y="167.86" width="17.02" height="1.29" />
            </g>
            <rect className="cls-9" x="369.54" y="359.22" width="20.5" height="1.29" />
            <rect className="cls-9" x="318.14" y="359.22" width="20.5" height="1.29" />
            <g id="LWPOLYLINE-244">
              <rect className="cls-9" x="1493.53" y="263.31" width="10.22" height=".64" />
            </g>
            <g id="LWPOLYLINE-245">
              <rect className="cls-9" x="1493.53" y="295.23" width="10.22" height=".64" />
            </g>
            <g id="LWPOLYLINE-246">
              <rect className="cls-9" x="1493.53" y="199.47" width="10.22" height=".64" />
            </g>
            <g id="LWPOLYLINE-247">
              <rect className="cls-9" x="1493.53" y="231.39" width="10.22" height=".64" />
            </g>
            <g id="LWPOLYLINE-248">
              <rect className="cls-9" x="1493.53" y="135.63" width="10.22" height=".64" />
            </g>
            <g id="LWPOLYLINE-249">
              <rect className="cls-9" x="1493.53" y="167.55" width="10.22" height=".64" />
            </g>
            <g id="LWPOLYLINE-250">
              <rect className="cls-9" x="1493.53" y="39.87" width="10.22" height=".64" />
            </g>
            <g id="LWPOLYLINE-251">
              <rect className="cls-9" x="1493.53" y="103.71" width="10.22" height=".64" />
            </g>
            <g id="LWPOLYLINE-252">
              <rect className="cls-9" x="1493.53" y="71.79" width="10.22" height=".64" />
            </g>
            <g id="LWPOLYLINE-253">
              <rect className="cls-9" x="1463.6" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-254">
              <rect className="cls-9" x="1431.68" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-255">
              <rect className="cls-9" x="1399.76" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-256">
              <rect className="cls-9" x="1367.84" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-257">
              <rect className="cls-9" x="1335.92" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-258">
              <rect className="cls-9" x="1243.34" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-259">
              <rect className="cls-9" x="1211.42" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-260">
              <rect className="cls-9" x="1179.5" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-261">
              <rect className="cls-9" x="1147.58" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-262">
              <rect className="cls-9" x="1115.66" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-263">
              <rect className="cls-9" x="1083.74" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-264">
              <rect className="cls-9" x="1051.82" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-265">
              <rect className="cls-9" x="1019.9" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-266">
              <rect className="cls-9" x="987.98" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-267">
              <rect className="cls-9" x="956.06" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-268">
              <rect className="cls-9" x="924.14" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-269">
              <rect className="cls-9" x="892.22" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-270">
              <rect className="cls-9" x="860.3" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-271">
              <rect className="cls-9" x="828.37" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-272">
              <rect className="cls-9" x="796.45" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-273">
              <rect className="cls-9" x="764.53" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-274">
              <rect className="cls-9" x="732.61" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-275">
              <rect className="cls-9" x="700.69" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-276">
              <rect className="cls-9" x="573.01" y="29" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-277">
              <rect className="cls-9" x="541.09" y="29" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-278">
              <rect className="cls-9" x="509.17" y="29" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-279">
              <rect className="cls-9" x="477.25" y="29" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-280">
              <rect className="cls-9" x="445.33" y="29" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-281">
              <rect className="cls-9" x="413.41" y="29" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-282">
              <rect className="cls-9" x="381.49" y="29" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-283">
              <rect className="cls-9" x="349.57" y="29" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-284">
              <rect className="cls-9" x="317.65" y="29" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-285">
              <rect className="cls-9" x="1480.1" y="29.03" width=".64" height="10.22" />
            </g>
            <g id="LWPOLYLINE-286">
              <rect className="cls-9" x="1495.44" y="298.16" width="10.22" height=".64" />
            </g>
            <g id="LWPOLYLINE-287">
              <rect className="cls-9" x="1495.44" y="354.56" width="10.22" height=".64" />
            </g>
            <polyline className="cls-8" points="910.27 261.14 910.27 240.69 908.99 240.69 908.99 261.14" />
            <polyline className="cls-8" points="908.99 237.49 908.99 217.04 910.27 217.04 910.27 237.49" />
            <g id="LWPOLYLINE-288">
              <rect className="cls-9" x="908.99" y="240.69" width="1.29" height="20.48" />
            </g>
            <g id="LWPOLYLINE-289">
              <rect className="cls-9" x="908.99" y="217.03" width="1.29" height="20.48" />
            </g>
            <polyline className="cls-8"
              points="1432.32 261.14 1432.32 240.69 1431.04 240.69 1431.04 261.14" />
            <polyline className="cls-8"
              points="1431.04 237.49 1431.04 217.04 1432.32 217.04 1432.32 237.49" />
            <g id="LWPOLYLINE-290">
              <rect className="cls-9" x="1431.04" y="240.69" width="1.29" height="20.48" />
            </g>
            <g id="LWPOLYLINE-291">
              <rect className="cls-9" x="1431.04" y="217.03" width="1.29" height="20.48" />
            </g>
            <g id="LWPOLYLINE-292">
              <rect className="cls-9" x="790.93" y="266.16" width="1.29" height="17.02" />
            </g>
          </g>
          <g id="walls">
            <rect className="cls-7" x="145.02" y="594.28" width="9.76" height="107.76" />
            <polygon className="cls-7"
              points="99.88 494.08 99.88 484.32 145.02 484.32 145.02 22.38 145.38 22.38 145.38 17.49 1516.61 17.49 1516.61 688.51 1506.85 688.51 1506.85 27.26 154.78 27.26 154.78 539.93 145.02 539.93 145.02 494.08 99.88 494.08" />
            <polygon className="cls-7"
              points="0 811.61 0 484.32 48.69 484.32 48.69 494.08 9.76 494.08 9.76 801.85 48.69 801.85 48.69 811.61 0 811.61" />
            <polygon className="cls-7"
              points="100.94 811.61 100.94 801.85 144.72 801.85 145.02 747.4 154.78 747.43 154.78 953.5 145.02 953.5 145.02 811.61 100.94 811.61" />
            <polyline className="cls-7" points="100.94 460.99 77.85 484.08 54.76 460.99" />
            <polyline className="cls-7" points="54.76 838.91 77.85 815.81 100.94 838.91" />
            <circle className="cls-5" cx="120.62" cy="616.06" r="6.89"
              onClick={(e) => onCircleClick({ event: e, node: '44' })}>
              <title>44</title>
            </circle>
          </g>
        </g>
        <g id="dots_double_sided_graphic">
          {getCheckedSVG()}
          <circle className="cls-4" cx="328.39" cy="359.86" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '4-1' })}>
              <title>4-1</title>
            </circle>
          <circle className="cls-4" cx="379.82" cy="359.86" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '4-2' })}>
              <title>4-2</title>
            </circle>
          <circle className="cls-4" cx="431.43" cy="131.21" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '40' })}>
              <title>40</title>
            </circle>
          <circle className="cls-4" cx="776.18" cy="109.39" r="6.89" 
            onClick={(e) => onCircleClick({ event: e, node: '15-3' })}>
              <title>15-3</title>
            </circle>
          <circle className="cls-4" cx="776.18" cy="159.39" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '15-2' })}>
              <title>15-2</title>
            </circle>
          <circle className="cls-4" cx="909.82" cy="146.36" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '19' })}>
              <title>19</title>
            </circle>
          <circle className="cls-4" cx="1042.91" cy="146.36" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '28-1' })}>
              <title>28-1</title>
            </circle>
          <circle className="cls-4" cx="1172.69" cy="146.36" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '28-2' })}>
              <title>28-2</title>
            </circle>
          <circle className="cls-4" cx="1431.68" cy="250.91" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '37' })}>
              <title>37</title>
            </circle>
          <circle className="cls-4" cx="1375.95" cy="301.65" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '36' })}>
              <title>36</title>
            </circle>
          <circle className="cls-4" cx="987.18" cy="301.65" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '34' })}>
              <title>34</title>
            </circle>
          <circle className="cls-4" cx="1247.94" cy="301.65" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '35' })}>
              <title>35</title>
            </circle>
          <circle className="cls-4" cx="716.46" cy="301.65" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '33' })}>
              <title>33</title>
            </circle>
          <circle className="cls-4" cx="1431.68" cy="227.27" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '38' })}>
              <title>38</title>
            </circle>
          <circle className="cls-4" cx="776.18" cy="209.36" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '15-1' })}>
              <title>15-1</title>
            </circle>
          <circle className="cls-4" cx="389.58" cy="169.14" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '9' })}>
              <title>9</title>
            </circle>
          <circle className="cls-4" cx="431.43" cy="227.62" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '39' })}>
              <title>39</title>
            </circle>
          <circle className="cls-4" cx="315.78" cy="166.54" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '8-3' })}>
              <title>8-3</title>
            </circle>
          <circle className="cls-4" cx="330.32" cy="166.54" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '8-2' })}>
              <title>8-2</title>
            </circle>
          <circle className="cls-4" cx="301.24" cy="166.54" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '8-1' })}>
              <title>8-1</title>
            </circle>
        </g>
        <g id="dots_single_sided_graphic">
          <circle className="cls-1" cx="137.85" cy="647.96" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '2' })}>
              <title>2</title>
            </circle>
          <circle className="cls-1" cx="305.92" cy="529.47" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '41' })}>
              <title>41</title>
            </circle>
          <circle className="cls-1" cx="372.57" cy="423.08" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '43' })}>
              <title>43</title>
            </circle>
          <circle className="cls-1" cx="438.32" cy="274.67" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '5' })}>
              <title>5</title>
            </circle>
          <circle className="cls-1" cx="365.85" cy="6.89" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '7' })}>
              <title>7</title>
            </circle>
          <circle className="cls-1" cx="908.5" cy="6.89" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '18' })}>
              <title>18</title>
            </circle>
          <circle className="cls-1" cx="1237.19" cy="88.07" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '32' })}>
              <title>32</title>
            </circle>
          <circle className="cls-1" cx="1106.22" cy="298.8" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '24' })}>
              <title>24</title>
            </circle>
          <circle className="cls-1" cx="577.07" cy="298.8" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '10' })}>
              <title>10</title>
            </circle>
          <circle className="cls-1" cx="1432.68" cy="91.87" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '31' })}>
              <title>31</title>
            </circle>
          <circle className="cls-1" cx="1495.44" cy="326.68" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '29' })}>
              <title>29</title>
            </circle>
          <circle className="cls-1" cx="1377.83" cy="275.08" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '30' })}>
              <title>30</title>
            </circle>
          <circle className="cls-1" cx="1226.66" cy="275.08" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '26' })}>
              <title>26</title>
            </circle>
          <circle className="cls-1" cx="1117.41" cy="275.08" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '25' })}>
              <title>25</title>
            </circle>
          <circle className="cls-1" cx="1096.43" cy="275.08" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '23' })}>
              <title>23</title>
            </circle>
          <circle className="cls-1" cx="987.18" cy="275.08" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '22' })}>
              <title>1</title>
            </circle>
          <circle className="cls-1" cx="965.64" cy="275.08" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '21' })}>
              <title>21</title>
            </circle>
          <circle className="cls-1" cx="856.39" cy="275.08" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '20' })}>
              <title>20</title>
            </circle>
          <circle className="cls-1" cx="697.25" cy="275.08" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '12' })}>
              <title>12</title>
            </circle>
          <circle className="cls-1" cx="566.44" cy="275.08" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '11' })}>
              <title>11</title>
            </circle>
          <circle className="cls-1" cx="332.13" cy="38.76" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '42' })}>
              <title>42</title>
            </circle>
          <circle className="cls-1" cx="525.45" cy="38.76" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '14-1' })}>
              <title>14-1</title>
            </circle>
          <circle className="cls-1" cx="557.37" cy="38.76" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '14-2' })}>
              <title>14-2</title>
            </circle>
          <circle className="cls-1" cx="664.4" cy="38.76" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '13' })}>
              <title>13</title>
            </circle>
        </g>
        <g id="dots_mannequin_copy">
          <circle className="cls-5" cx="344.01" cy="414.87" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '3-3' })}>
              <title>3-3</title>
            </circle>
          <circle className="cls-5" cx="348.09" cy="166.54" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '6' })}>
              <title>6</title>
            </circle>
          <circle className="cls-5" cx="333.82" cy="396.07" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '3-2' })}>
              <title>3-2</title>
            </circle>
          <circle className="cls-5" cx="323.41" cy="414.87" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '3-1' })}>
              <title>3-1</title>
            </circle>
          <circle className="cls-5" cx="921.21" cy="290.04" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '17-1' })}>
              <title>17-1</title>
            </circle>
          <circle className="cls-5" cx="911.02" cy="271.24" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '17-2' })}>
              <title>17-2</title>
            </circle>
          <circle className="cls-5" cx="900.61" cy="290.04" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '17-3' })}>
              <title>17-3</title>
            </circle>
          <circle className="cls-5" cx="1442.93" cy="290.04" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '27-1' })}>
              <title>27-1</title>
            </circle>
          <circle className="cls-5" cx="1432.73" cy="271.24" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '27-2' })}>
              <title>27-2</title>
            </circle>
          <circle className="cls-5" cx="1422.33" cy="290.04" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '27-3' })}>
              <title>27-3</title>
            </circle>
        </g>
        <g id="info">
          <text className="cls-3" transform="translate(24.34 656.1)">
            <tspan x="0" y="0">Foyer</tspan>
          </text>
          <text className="cls-3" transform="translate(594.48 75.09)">
            <tspan x="0" y="0">FR</tspan>
          </text>
          <text className="cls-3" transform="translate(38.02 444.54)">
            <tspan x="0" y="0">Ent/Exit</tspan>
          </text>
          <text className="cls-3" transform="translate(38.02 867.65)">
            <tspan x="0" y="0">Ent/Exit</tspan>
          </text>
          <circle className="cls-6" cx="120.62" cy="647.87" r="6.89"
            onClick={(e) => onCircleClick({ event: e, node: '1' })}>
              <title>1</title>
            </circle>
        </g>
      </svg>
    </Div>
  </>

  );
};

export default FortyKModel;