import React, { useState } from "react";
import { Button, message, Modal, Progress, Spin } from "antd";
import { connect } from "react-redux";
import { LoadingOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Div } from "./styles";
import { FileUploadList, Node, State } from "../../models/state";
import ImageUploader from "../../ImageUploader";
import {
  getNode,
  getS3PostUrl,
  uopdateImageMeta,
  uploadPhoto,
} from "../../services";
import SVG from "../svg";

const Store = (props: any) => {
  const { selectedStore } = props;
  const [fileUploadList, setFileUploadList] = useState<FileUploadList[]>([]);
  const [fileDataIn, setFileDataIn] = useState<any>({});
  const [uploading, setUploading] = useState(false);
  const [showSpin, setShowSpin] = useState(false);
  const [percent, setPercent] = useState<number>(0);
  let uploadCount = 0;
  const [selectedBox, setSelectedBox] = useState<any>();

  const onListUpdate = (data: FileUploadList[]) => {
    setFileUploadList([...data]);
  };
  const onFileDataUpdate = (data: any) => {
    setFileDataIn({ ...data });
  };
  const onFileToggle = (file: string) => {
    setSelectedBox({
      ...selectedBox,
      fileKey: file,
      fileMeta: selectedBox.weeks
        .find((d: any) => d.name === selectedBox.wk)
        ?.files?.find((s: any) => s.name === file),
      clickedOn: new Date(),
    });
  };
  const onWeekToggle = (wk: string) => {
    const files = selectedBox.weeks.find((d: any) => d.name === wk)?.files;
    setSelectedBox({
      ...selectedBox,
      fileKey: files[0].name,
      wk,
      fileKeys: files.map((s: any) => s.name),
      fileMeta: files[0],
      clickedOn: new Date(),
    });
  };
  const onAddWeekChange = async (wk: string) => {
    const { data } = await getNode(selectedBox.node, selectedStore.name);
    const files = data.weeks.find((d: any) => d.name === wk)?.files;
    const lData = processNode(data);
    setSelectedBox({
      ...selectedBox,
      ...lData,
      fileKey: files[0].name,
      wk,
      fileKeys: files.map((s: any) => s.name),
      fileMeta: files[0],
      clickedOn: new Date(),
    });
  };

  const onDeleteWeekChange = async () => {
    const { data } = await getNode(selectedBox.node, selectedStore.name);
    const lData = processNode(data);
    setSelectedBox({
      ...lData,
      clickedOn: new Date(),
    });
  };
  const onCopyComplete = async () => {
    const { data } = await getNode(selectedBox.node, selectedStore.name);
    const files = data.weeks.find((d: any) => d.name === selectedBox.wk)?.files;
    const lData = processNode(data);
    setSelectedBox({
      ...selectedBox,
      ...lData,
      fileKey: selectedBox.fileKey,
      wk: selectedBox.wk,
      fileKeys: files.map((s: any) => s.name),
      fileMeta: files?.find((s: any) => s.name === selectedBox.fileKey),
      clickedOn: new Date(),
    });
  };

  const processNode = (data: any) => {
    const SW = data.weeks.sort((a: any, b: any) => {
      let aa = a.name.split(" ")[1];
      let bb = b.name.split(" ")[1];
      return parseInt(aa) - parseInt(bb);
    });
    const files = SW[0]?.files || [];
    return {
      ...data,
      weeks: SW,
      fileKey: files[0]?.name || "DUMMYIMAGEWITHNOWEEK",
      wk: SW[0]?.name || "",
      fileKeys: files.map((s: any) => s.name),
      fileMeta: files[0] || {},
    } as Node;
  };
  const onCircleClick = async (dataIn: any) => {
    const { node, x, y } = dataIn;
    setShowSpin(true);
    const { data } = await getNode(node, selectedStore.name);
    if (data.status === "NotFound") {
      message.error(node || "No Data Found For the Node");
      setShowSpin(false);
      return;
    }
    setFileDataIn({
      ...fileDataIn,
      [data.node]: {
        posCode: fileDataIn[data.node]
          ? fileDataIn[data.node].posCode
          : data.posCode,
        posElement: fileDataIn[data.node]
          ? fileDataIn[data.node].posElement
          : data.posElement,
      },
    });
    setShowSpin(false);
    setSelectedBox({ ...processNode(data), x, y, clickedOn: new Date() });
  };
  const updateFileUploadList = (fileKey: string) => {
    const index = fileUploadList.findIndex((d) => d.fileKey === fileKey);
    const newFileList = fileUploadList.slice();
    const [uploadedFile] = newFileList.splice(index, 1);
    uploadedFile.isUploaded = true;
    delete uploadedFile.croppedImage;
    delete uploadedFile.cropper;
    delete uploadedFile.file;
    const updatedList = [...newFileList, uploadedFile];

    setFileUploadList(updatedList);
    const data = [...updatedList.filter((d) => !d.isUploaded)];
    setPercent(Math.floor(((uploadCount - data.length) / uploadCount) * 100));

    if (data.length === 0) {
      setTimeout(() => {
        setUploading(false);
        message.success("File(s) uploaded successfully");
      }, 10);
    } else {
      uploadSingleFile(data[0]);
    }
  };
  const handleUpload = () => {
    // console.log('upload start')
    setUploading(true);
    setPercent(3);
    const data = [...fileUploadList.filter((d) => !d.isUploaded)];
    uploadCount = data.length;
    // console.log('upload start', data.length)
    uploadSingleFile(data[0]);
  };

  const uploadSingleFile = async (fileObj: any) => {
    try {
      // console.log('upload file start', fileObj.fileKey)

      const response = await getS3PostUrl(fileObj.fileKey);
      await uploadPhoto(
        response.data.url,
        fileObj.croppedImage || fileObj.file,
        { contentType: fileObj.file.type }
      );
      const { node, store } = fileObj.nodeData;
      const { posCode, posElement } = fileDataIn[node];
      await uopdateImageMeta(
        node,
        store,
        fileObj.fileKey,
        props.user,
        posCode,
        posElement
      );
      updateFileUploadList(fileObj.fileKey);
    } catch {
      message.error("Failed to upload File(s)");
      setUploading(false);
    }
  };
  return (
    <Div>
      <div className="layOut">
        <div className="svgSection">
          <SVG
            onCircleClick={onCircleClick}
            fileUploadList={fileUploadList}
            store={selectedStore?.name}
          />
        </div>
      </div>

      <div>
        <Modal closable={false} open={uploading} title="Uploading" footer={[]}>
          <Progress strokeLinecap="butt" percent={percent} />
        </Modal>
      </div>
      <div className="upload-div">
        {showSpin && (
          <Spin
            className="spinL"
            indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />}
          />
        )}
        <div className="dialog-div">
          {selectedBox ? (
            <ImageUploader
              fileUploadListIn={fileUploadList}
              onListUpdate={onListUpdate}
              fileDataIn={fileDataIn}
              onFileDataUpdate={onFileDataUpdate}
              selectedBoxIn={selectedBox}
              onFileToggle={onFileToggle}
              onWeekToggle={onWeekToggle}
              onAddWeekChange={onAddWeekChange}
              user={props.user}
              onCopyComplete={onCopyComplete}
              onDeleteWeekChange={onDeleteWeekChange}
            ></ImageUploader>
          ) : (
            <></>
          )}
        </div>
      </div>

      <Button
        type="primary"
        loading={uploading}
        disabled={fileUploadList.filter((d) => !d.isUploaded).length === 0}
        onClick={handleUpload}
        className="uploadAll"
        icon={<CloudUploadOutlined />}
      >{`Confirm ${
        fileUploadList.filter((d) => !d.isUploaded).length || ""
      } change(s)`}</Button>
    </Div>
  );
};
const mapStateToProps = (state: State) => {
  return {
    selectedStore: state.selectedStore,
    user: state.user?.user,
  };
};
export default connect(mapStateToProps)(Store);
