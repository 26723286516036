import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { Div } from "./styles";
import { State } from "../../models/state";
import { useEffect, useState } from "react";
import { getStores } from "../../services";
const Stores = (props: any) => {
  const [stores, setStores] = useState([]);
  const navigate = useNavigate();
  const handleClick = (store: any) => {
    props.setSelectedStore(store);
    props.setNav({ title: store.displayName, showLogo: false });
    navigate(`/store/${store.name}`);
  };
  useEffect(() => {
    props.setNav({ title: "", showLogo: true });
    updateStores();
  }, []);

  const updateStores = async () => {
    const { data } = await getStores();
    setStores(data);
  };

  return (
    <Div>
      <div className="subHeader">Chose a store to edit</div>
      <div className="stores">
        {stores.map((s: any, i: number) => {
          return (
            <div key={i} className="storeMain">
              <div
                className={s?.enabled ? "store" : "store store_disabled"}
                onClick={() => s?.enabled && handleClick(s)}
              >
                <span>{s.displayName}</span>
              </div>

              <span style={{ paddingTop: "1rem", fontSize: "1rem" }}>
                Updated on
              </span>
              <span style={{ fontSize: "1rem" }}>
                {new Date(s.updatedOn).toLocaleDateString()}
              </span>
            </div>
          );
        })}
      </div>
    </Div>
  );
};
const mapStateToProps = (state: State) => {
  return {
    stores: state.stores,
  };
};

// Map dispatch actions to props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedStore: (selectedStore: any) =>
      dispatch({ type: "SET_SELECTED_STORE", selectedStore }),
    setNav: (data: any) => dispatch({ type: "SET_NAV", data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Stores);
