import styled from "styled-components";

export const Div = styled.div`
margin: 20px;
width: 100%;
.uploadAll {
    position: fixed;
    bottom: 20px;
    right: 20px;

}
.layOut{
    width: 100%;
    display: flex;
}
.svgSection{
width: 100%;
margin: 2rem;
}
.spinL{
    
    position: fixed;
    top: 45vh;
}
.dialog-div{
    position: fixed;
    width: 50%;
}
`;
