import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import "./fonts/Nunito-Italic-VariableFont_wght.ttf";
import "./fonts/Nunito-VariableFont_wght.ttf";
import { Provider } from "react-redux";

import axios from "axios";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

axios.interceptors.request.use(
  (req) => {
    if (localStorage.getItem("authToken"))
      req.headers.set("x-authorization", localStorage.getItem("authToken"));
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
