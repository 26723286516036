import React, { useEffect } from "react";
import type { FC } from "react";
import "antd/dist/reset.css";
import "./App.css";

import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from "./components/login";
import Stores from "./components/stores/stores";
import Store from "./components/store/store";
import NavBar from "./components/navbar/navBar";
import { colors, isProd } from "./utils";

const App: FC = () => {
  useEffect(() => {
    if (isProd()) {
      document.body.classList.add("prod-body");
    } else {
      document.body.classList.add("dev-body");
    }
    return function () {};
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <div className="routeHome">
          <Routes>
            <Route path="" index element={<Login />} />
            <Route path="stores" element={<Stores />} />
            <Route path="store/:id" element={<Store />} />
          </Routes>
        </div>
        <div
          className="footer"
          style={{
            background: isProd() ? colors.prod : colors.dev,
          }}
        >
          © 2023 Add Reality Limited
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
